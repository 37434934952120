import Layout from '@/layout'

const settingRouter = {
  path: '/',
  component: Layout,
  code:'ConfigMangemnt',
  name: 'ConfigMangemnt',
  meta: {
    title: 'ConfigMangemnt',
    icon: 'setting'
  },
  children: [
    {
      path: '/system/paySetting',
      component: () => import('@/views/paySetting/index'),
      name: 'PayConfig',
      meta: { title: 'PayConfig' }
    },
    {
      path: '/system/siteSetting',
      component: () => import('@/views/siteSetting/index'),
      name: 'SiteConfig',
      meta: { title: 'SiteConfig' }
    },
    {
      path: '/system/smsSetting',
      // component: '',
      component: () => import('@/views/smsSetting/index'),
      name: 'SmsCofig',
      meta: { title: 'SmsCofig' },
      children: [
        {
          path: '/',
          component: () => import('@/views/smsSetting/list/index'),
          name: 'SmsCofigList',
          meta: { title: 'SmsCofigList' },
        },
        {
          path: '/system/smsSetting/smsTmp',
          component: () => import('@/views/smsSetting/smsTmp/index'),
          name: 'SmsTmpConfig',
          meta: { title: 'SmsTmpConfig' }
        },
      ] 
    }, 
    {
      path: '/system/mailSetting',
      // component: '',
      component: () => import('@/views/emailSetting/index'),
      name: 'MailCofig',
      meta: { title: 'MailCofig' },
      children: [
        {
          path: '/',
          component: () => import('@/views/emailSetting/list/index'),
          name: 'EmailCofigList',
          meta: { title: 'EmailCofigList' },
        },
        {
          path: '/system/smsSetting/emailTmp',
          component: () => import('@/views/emailSetting/emailTmp/index'),
          name: 'EmailTmpConfig',
          meta: { title: 'EmailTmpConfig' }
        },
      ] 
    }, 
  ]
}

export default settingRouter