import Cookies from 'js-cookie'
import { getBasePriceUnitService } from '@/services/common'

const state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  size: Cookies.get('size') || 'medium',
  pageing:{
    total:10,
    current:1,
    pageSize:10,
  },
  statusList:[
    {
      value:1,
      label:"enable",
      stringValue:"1",
    },{
      value:2,
      label:"disable",
      stringValue:"2",
    },
  ],
  activeMenu:'',
  unitTable:{
    1:"year",
    2:"month",
    3:"week",
    4:"day",
    5:"hour"
  },
  autoRenewList:[
    {
      value:1,
      label:"autoRenew",
    },{
      value:1,
      label:"manualRenew",
    }
  ],
  currencySymbolTable:{
    'CNY':'￥',
    'USD':'$',
  },
  basePriceUnit:"1",
  basePriceCurrency:'CNY',
  originAddress:'',
}

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_ACTIVEMENU: (state, menu) => {
    state.menu = menu
    Cookies.set('activeMenu', menu)
  },
  
  SET_BASE_PRICE_UNIT: (state, unit) => {
    state.basePriceUnit = unit
  },
  SET_BASE_PRICE_CURRENCY: (state, currency) => {
    state.basePriceCurrency = currency
  },
  SET_ADDRESS: (state, originAddress) => {
    state.originAddress = originAddress
    // Cookies.set('originAddress', originAddress)
  },
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  setActiveMenu({ commit }, menu) {
    commit('SET_ACTIVEMENU', menu)
  },
  
  /**
   * 查询基础价格单位
   */
  getBasePriceUnit({ commit }) {
    return new Promise((resolve, reject) => {
      getBasePriceUnitService().then(response => {
        const { data } = response
        commit('SET_BASE_PRICE_UNIT', data.unit)
        commit('SET_BASE_PRICE_CURRENCY', data.currency)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  /**
   * 设置url
   */
  setBaseUrl({commit}){
    
    // commit('SET_ADDRESS','http://10.0.0.89:8080')
    commit('SET_ADDRESS',window.location.origin)
    // console.log(window.location.origin)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
