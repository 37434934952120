<template>
  <div class="sub-menu">
    <label v-for="menu in menuInfo" :key="menu.code">
      <el-menu-item v-if="!menu.children && menu.show_status === 1" :key="menu.code" :data="menu" :index="menu.router" :class="{'submenu-title-noDropdown':!isNest}"
        @click="goto(menu.router)">
          <svg-icon :icon-class="menu.icon" />
          <span slot="title">{{$t('menu.'+ toLowerCaseFirst(menu.code))}}</span>
      </el-menu-item>
      <el-menu-item v-if="menu.children && menu.children[0] && !menu.showChildren && menu.show_status === 1" :key="menu.code" :data="menu" :index="menu.router" :class="{'submenu-title-noDropdown':!isNest}"
        @click="goto(menu.router)">
          <svg-icon :icon-class="menu.icon" />
          <span slot="title">{{$t('menu.'+ toLowerCaseFirst(menu.code))}}</span>
      </el-menu-item>
      <el-submenu v-if="menu.children && menu.children[0] && menu.showChildren && menu.show_status === 1" @click.native="goto(menu.router)" :key="menu.code" :data="menu" :index="menu.id">
        <template slot="title">
          <svg-icon :icon-class="menu.icon" />
          <span>{{$t('menu.'+ toLowerCaseFirst(menu.code))}}</span>
        </template>
        <SubMenu :menuInfo="menu.children"></SubMenu>
      </el-submenu>
    </label>
  </div>
</template> 

<script>
import FixiOSBug from './FixiOSBug'
import { toLowerCaseFirst } from '@/utils'

export default {
  name:'SubMenu',
  mixins: [FixiOSBug],
  props: {
    // menuInfo:'menuInfo',
    
    menuInfo: {
      type: Array,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {}
  },
  methods: {
    //跳转
    goto (menu) {
      if(menu){
        console.log(menu)
        this.$router.push(menu)
        this.$store.dispatch('app/setActiveMenu', menu)
      }
    },
    
    toLowerCaseFirst(str) {
      return toLowerCaseFirst(str)
    },
  },
}
</script>

<style lang="scss">
.hideSidebar{
  .sub-menu{
    .el-menu-item{
      .el-tooltip{
        i{
          width: 100%;
        }
      }
    }
    li{
      .el-submenu__title{
        i{
          width: 100%;
        }
      }
    }
  }
}
</style>