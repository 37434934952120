// export default service
import axios from 'axios';
import Vue from 'vue';
// import {notification} from 'ant-design-vue'
import router from '@/router'

import store from '@/store';
import { getToken, setToken, removeToken } from '@/utils/auth'
import { Message } from 'element-ui';

// let baseURL = '/api'

// export const baseURL = '/api'
// if (process.env.NODE_ENV == 'development') {    
//   console.log('development')
//   baseURL = '/api';
// } 
// else if (process.env.NODE_ENV == 'production') {    
//   console.log('production')  
//   baseURL = 'http://10.0.0.89:10080/';
// }else{
//   baseURL = 'http://10.0.0.89:10080/';
// }import Vue from 'vue'

// axios.defaults.baseURL = baseURL
// axios.defaults.baseURL = baseURL
Vue.prototype.$http = axios
axios.defaults.timeout = 60000
axios.defaults.withCredentials = true
axios.defaults.responseType = "json"
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
const request = axios.create({
	// baseURL:baseURL, 		      // 后台服务地址
	timeout: 60000, 		      // 请求超时时间1分钟
	responseType: "json",
	withCredentials: true    // 是否允许带cookie这些
})

let hrefArr = window.location.href.split("/")
request.interceptors.request.use( 
  config => {        
      // 每次发送请求之前判断cookie中是否存在token        
      // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
      // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
      const _token = getToken()
      // console.log(_token)
      // if(token && ( hrefArr[4] !== 'login' || hrefArr[4] !== '' )){
        // console.log(hrefArr)
        if(_token){
          const token = JSON.parse(_token)
          config.headers["Authorization"] = `${token.token_type} ${token.access_token}` 
        }
      // }
      return config  
  },
  error => {        
    console.log(error)
      return Promise.error(error)    
})

request.interceptors.response.use(
  response => {
    const { status, headers, data, body } = response
    // console.log(body)
    // console.log(response)
    if(response.status === 200){
      return Promise.resolve(
        {
          success:true,
          status: status,
          headers:headers,
          // headers: {...defaultHeader,headers},          
          data:data,
        })
    }else{
      return Promise.reject(
        {
          success: false, 
          status: status, 
          message: response.error.message ? response.error.message : ''
      })
    }
  },
  error => {
    const { response } = error
    let msg
    let statusCode
    let statusText
    if (response && response instanceof Object) {
      const { data } = response
      statusCode = response.status
    //   statusText = statusCode === 401 || statusCode === 403 ? '请重新登录' : response.statusText
      statusText = statusCode === 403 ? '请重新登录' : response.statusText
    //   if(statusCode === 401 || statusCode === 403){
      if(statusCode === 401){
        if(data.error.code === 9999){
          router.push('/login')
        }else{
          router.push('/401')
          Message.error({message: data.error.message})
        }
          // let hrefArr = window.location.href.split("/")
          // let ip = hrefArr[0]+'//'+hrefArr[2]+'/ps'
        // console.log(ip+'ps')
        // this.$router.push({name:'login'})
        
        // store.clearCookie('x-user-name')
        // store.clearCookie('x-password')
        // window.location.href = ip
        // router.replace({
        //   path:"/login",
        //   querry:{redirect:router.currentRoute.fullPath}//从哪个页面跳转
        // })
      }
      // console.log(response)
      // console.log(data)
      msg = (data && data.error.message) || statusText
    } else {
      statusCode = 600
      msg = error.message || '网络连接错误'
    }
    
      // notification.error({message:msg})
      return Promise.reject({ success: false, statusCode, message: msg })
    // if (error.response && error.response.status){
    //   switch(error.response.status){
    //     case 401:               
    //     statusText = '请重新登陆'
        
    //     clearCookie('username')
    //     clearCookie('ps-x-xsrf-token')
    //     // notification.error({message:'请重新登陆'})
    //     // router.replace({                        
    //     //     path: '/login',                        
    //     //     query: { redirect: router.currentRoute.fullPath } 
    //     // });
    //     router.push({name:'login'})
    //     break;
    //     case 403:            
    //     statusText = '暂无权限'        
    //     // notification.error({message:'暂无权限'})
    //     // router.replace({                        
    //     //     path: '/login',                        
    //     //     query: { redirect: router.currentRoute.fullPath } 
    //     // });
    //     break;
    //   }
      
    //   notification.error({message:statusText})
    //   return Promise.resolve({
    //     success:false,
    //     message: error.response.msg,
    //     status: error.response.status,
    //     headers: error.response.headers,     
    //   })
    // }else{
    //   return Promise.resolve({error})
    // }
  }
)

/** 
 * @desc get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params){    
  console.log(params)
  return new Promise((resolve, reject) =>{  
    request.get(url, {            
      params: params        
    })        
    .then(res => {    
      console.log(res)      
      resolve(res)      
    })        
    .catch(err => {  
      // console.log(err)          
      reject(err)        
    })    
  })
}
/** 
 * @desc post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params = {}) {    
  console.log("coming")
  return new Promise((resolve, reject) => {         
    request.post(url, params
    //   {
    //   params: params
    // }
    )        
      .then(res => {            
        resolve(res)    
      })        
      .catch(err => {     
        console.log(err)       
        reject(err)        
      })    
  })
}

/**
 * @desc put方法，对应put请求
 */
export function put(url, params = {}) {    
  return new Promise((resolve, reject) => {         
    request.put(url,params)        
      .then(res => {            
        resolve(res.data);        
      })        
      .catch(err => {            
        reject(err.data)        
      })    
  })
}

/** 
 * @desc delete方法，对应delete请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function deleteData(url, params){    
  return new Promise((resolve, reject) =>{  
    request.delete(url, {            
      params: params        
    })        
    .then(res => {            
      resolve(res.data)      
    })        
    .catch(err => {            
      reject(err.data)        
    })    
  })
}

export default request 