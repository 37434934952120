<template>
  <div class="sidebar-logo-container" :class="{'collapse':collapse}">
    <transition name="sidebarLogoFade">
<!--      <div v-if="collapse" key="collapse" class="sidebar-logo-link">-->
<!--        <img v-if="logo" src="../../../assets/logo-small.png" class="sidebar-logo" @click="goto('/dashboard')">-->
<!--        <h1 v-else class="sidebar-title">{{ title }} </h1>-->
<!--      </div>-->
      <!-- <router-link v-if="collapse" key="collapse" class="sidebar-logo-link"> -->
      <!-- </router-link> -->
      <!-- <router-link v-else key="expand" class="sidebar-logo-link"> -->
      <div v-if="!collapse" key="expand" class="sidebar-logo-link">

<!--        <img v-if="logo" src="../../../assets/logo.png" class="sidebar-logo" @click="goto('/dashboard')"> -->
        <h1 v-if="!logo" class="sidebar-title">{{ title }} </h1>
      </div>
      <!-- </router-link> -->
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: 'CNGDC',
      logo: '@/assets/logo.png'
    }
  },
  methods: {

    //跳转
    goto (menu) {
      if(menu){
        console.log(menu)
        this.$router.push(menu)
        this.$store.dispatch('app/setActiveMenu', menu)
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #041a2f;
  text-align: center;
  overflow: hidden;

  & .sidebar-logo-link {
    height: 100%;
    width: 100%;
    cursor: pointer;

    & .sidebar-logo {
      width: 100%;
      padding: 0 10px;
      height: 32px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      margin: 0;
      color: #fff;
      font-weight: 600;
      line-height: 50px;
      font-size: 14px;
      font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
      vertical-align: middle;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
