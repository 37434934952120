import zhLang from 'element-ui/lib/locale/lang/zh-CN'
import vexZhCN from 'vxe-table/lib/locale/lang/zh-CN';

const cn = {
  menu:{
    All:"全部",
    Dashboard:"控制台",
    CustomerMangment:"客户管理",
    CustomerList:"客户列表",
    CustomerDetails:"客户详情",
    GradeCfg:"等级配置",
    Audit:"认证审核",
    AuditList:"审核列表",
    BusinessMangment:"业务管理",
    BMS:"裸金属服务器",
    BmsDetails:"裸金属服务器详情",
    SpecificationsManage:"规格配置",
    EBS:"块存储云盘",
    SBG:"线路带宽",
    EIP:"IP列表",
    VPC:"VPC列表",
    DiskSnap:"云盘快照",
    DiskCustomImage:"自定义镜像",
    OrderMangment:"订单管理",
    OrderList:"订单列表",
    FinanceMangment:"财务管理",
    FundFlows:"财务流水",
    ConsumeRecord:"消费记录",
    ChargeRecord:"充值记录",
    TicketMangment:"工单管理",
    TicketList:"工单列表",
    TicketDetails:"工单详情",
    TicketType:"类型配置",
    NoticeMangment:"通知管理",
    InnerMsg:"站内信",
    InnerMsgType:"分类配置",
    MsgHistory:"消息记录",
    ProductMangemnt:"产品管理",
    DataCenter:"数据中心",
    BmsCombo:"BMS关联配置",
    BmsProduct:"BMS产品",
    EbsProduct:"块存储产品",
    LinesProduct:"线路产品",
    EipProduct:"EIP产品",
    ImagesProduct:"镜像产品",
    TimeRatio:"购买时长",
    GenericProduct:"通用产品",
    ConfigMangemnt:"配置管理",
    SmsCofig:"短信配置",
    SmsCofigList:"短信配置列表",
    SmsTmpConfig:"短息模板配置",
    MailCofig:"邮件配置",
    EmailCofigList:"邮件配置列表",
    EmailTmpConfig:"邮件模板配置",
    PayConfig:"支付配置",
    SiteConfig:"网站配置",
    SystemMangemnt:"系统管理",
    SysMenu:"菜单管理",
    SysRole:"角色管理",
    SysUser:"账号管理",
    SysUserDetails:"账号详情",
    EbsPrice:"块存储价格配置",
    AuditType:"认证类型",
    PersonalAudit:"个人认证",
    BusinessAudit:"企业认证",
    AuditDetails:"认证详情",
    CouponMangemnt:"优惠折扣",
    CouponList:"优惠券管理",
    CouponLog:"领取记录",
    Regions:"地域配置",
    NodePrice:"BMS产品价格配置",
    NodeCPU:"CPU规格",
    NodeRAM:"内存规格",
    NodeHDD:"硬盘规格",
    NodeNIC:"网卡规格",
    NodeGPU:"GPU规格",
    LinesPrice:"线路产品价格配置",
    SmsTpl:"短信模版",
    MailTpl:"邮件模版",
    RequestLog:"请求日志",
    VpcProduct:"VPC产品"
  },
  options:{
    option:"操作",
    edit:"编辑",
    add:"新增",
    delete:"删除",
    query:"查询",
    cancel:"取消",
    save:"保存",
    close:"关闭",
    search:"搜索",
    addSome:"新建{des}",
    editSome:"编辑{des}",
    settingPrice:"价格配置",
    syncImage:"同步镜像",
    record:"入账",
    invalid:"作废",
    powerOff:"关机",
    forcedOff:"强制关机",
    powerOn:"开机",
    forcedOn:"强制开机",
    restart:"重启",
    forcedRestart:"强制重启",
    resetPwd:"重置密码",
    reinstall:"重装系统",
    destroy:"销毁",
    networking:"连接网络",
    disconnection:"断开网络",
    openAutoRenew:"开启自动续费",
    closeAutoRenew:"关闭自动续费",
    reCreate:"重新申请创建",
    create:"创建",
    details:"详情",
    submit:"提交",
    goback:"返回",
    updateMemo:"更新备注",
    clickUpload:"点击上传",
    clickCopy:"点击复制",
    audited:"通过",
    reject:"驳回",
    examineAndVerify:"审核",
    moreOpt:"更多操作",
    disableSome:"禁用{msg}",
    enableSome:"启用{msg}",
    clickDisableSome:"点击禁用{msg}",
    clickEnableSome:"点击启用{msg}",
    settingSome:"配置{des}",
  },
  common:{
    status:"状态",
    createTime:"创建时间",
    expiredAt:"到期时间",
    showStatus:"是否显示",
    show:"显示",
    hidden:"隐藏",
    enable:"启用",
    disable:"禁用",
    remarks:"备注",
    aData:"该数据",
    name:"名称",
    realName:"真实姓名",
    roleName:"角色名称",
    sequence:"排序",
    icon:"图标",
    keyword:"关键字",
    updateTime:"更新时间",
    productName:"产品名称",
    start:"区间开始",
    end:"区间结束",
    price:"价格",
    productId:"产品ID",
    productModel:"产品型号",
    doorosProductModel:"Dooros产品型号",
    productCode:"产品编号",
    dataCenter:"数据中心",
    priceOfHours:"价格(小时)",
    pure:"纯裸",
    mix:"混裸",
    prepaid:"预付费",
    payment:"按量付费",
    currency:"货币类型",
    oppositeTradeId:"交易渠道流水号",
    oppositeTradeAccount:"交易渠道帐号",
    tradeChannel:"交易渠道",
    amount:"金额",
    recharge:"充值",
    refund:"退款",
    userNo:"用户编号",
    userName:"用户名称",
    chargeType:"付费类型",
    autoRenew:"自动续费",
    manualRenew:"手动续费",
    sysDisk:"系统盘",
    dataDisk:"数据盘",
    isCertified:"实名认证",
    isExpired:"已到期",
    startTime:"开始时间",
    endTime:"结束时间"
  },
  login:{
    login:"登录",
    logout:"登出",
    password:"密码",
  },
  dashboard:{
    salesInfo:"销售信息",
    totalRefundAmount:"总退款额",
    totalSalesAmount:"总销售额",
    orderInfo:"订单信息",
    orderAmount:"订单总量",
    instanceInfo:"产品信息",
    salseAmount:"销售总额",
    queryByYear:"查询年份",
    queryByMonth:"查询月份",
    customCount:"客户数量",
    yearSale:"年销售",
    monthSale:"月销售",
    refundInfo:"退款信息",
    yearRefund:"年退款",
    monthRefund:"月退款",
    yearReg:"年增长",
    monthReg:"月增长",
    userRegInfo:"用户注册增长信息",
    userReg:"用户注册增长数据",
    disk:"磁盘数量",
    eip:"IP数量",
    host:'已开通服务器数量',
    willExpHost:"即将到期服务器数量",
    expHost:"已到期服务器数量",
    images:"镜像数量",
    snap:"快照数量"
  },
  system:{
    menu:"菜单",
    menuName:"菜单名称",
    code:"菜单编码",
    sequence:"排序值",
    icon:"菜单图标",
    path:"访问路由",
    parentMenu:"上级菜单",
    template:"快速创建模板",
    actionCode:"动作编号",
    actionName:"动作名称",
    actionManagement:'菜单动作(按钮)管理',
    resourceManagement:"资源管理(服务端接口映射)",
    method:"请求方式",
    requestPath:"请求路径",
    name:"名称",
  },
  datacenter:{
    datacenter:"数据中心",
    regionSetting:"地域管理",
    regionId:"地域编码",
    regions:"地域",
    cluster_id:"数据中心",
    zone_id:"数据中心编号",
    expiredRetainDay:"过期保留时间(天)",
    isNeedCert:"是否需要实名认证",
    need:"需要",
    unwanted:"不需要",
    expiredRetainDayDes:"传入10000表示，过期不保留"
  },
  ebs:{
    ebs:"块存储",
    dataCenterId:"数据中心ID",
    doorosModel:"存储池",
    price:"块存储价格",
    isSysDiskNeedPay:"系统盘是否收费",
    dataDiskMinSize:"最小购买容量",
    dataDiskMaxSize:"最大购买容量",
    sysDiskSize:"作系统盘时容量",
    code:"编号",
    needPay:"收费",
    free:"免费"
  },
  node:{
    node:"裸金属节点",
    cpu:"CPU",
    coreNum:"核心数",
    cpuCoreNum:"CPU核心数",
    cpuIcon:"图标",
    cpuName:"名称",
    flops:"算力值(FTlop/s)",
    icon:"产品图标",
    memory:"内存",
    memoryMemo:"内存描述",
    memoryModel:"内存型号",
    memorySize:"内存大小(GB)",
    ssd:"SSD",
    ssdMemo:"硬盘描述",
    ssdModel:"硬盘型号",
    ssdSize:"硬盘大小(GB)",
    threadNum:"线程数",
    cpuThreadNum:"CPU线程数",
    nodeType:"节点类型",
    tags:"标签",
    addTag:"新增标签",
    price:"价格(小时)",
    productId:"产品节点ID",
    nodePrice:"节点价格",
    pure:"纯裸",
    mix:"混裸",
    virtualMachine:"虚拟机",
    productTag:"产品标签",
    tagsManage:"产品标签管理",
    tagsKey:"标签Key",
    tagsValue:"标签Value",
    doorosModel:"关联节点",
    bmsCode:"BMS规格",
    cpuSpecifications:"CPU规格",
    cpuManage:"CPU规格管理",
    specificationsManage:"规格管理",
    specificationsManageContent:"CPU规格、GPU规格、硬盘规格、网卡规格、内存规格",
    code:"编码",
    gpu:"GPU",
    gpuSpecifications:"GPU规格",
    videoMemory:"显存",
    videoMemorySize:"显存(GB)",
    gpuIcon:"图标",
    hardDiskSpecifications:"硬盘规格",
    networkCardSpecifications:"网卡规格",
    ramSpecifications:"内存规格",
    capacity:"容量",
    capacitySize:"容量(GB)",
    gpuNum:"GPU数量",
    addGpu:"增加GPU",
    delChooseGpu:"删除这组GPU",
    hailinglong:"海玲珑",
    shanlinglong:"山玲珑",
    huolinglong:"火玲珑"
  },
  lines:{
    lines:'线路产品',
    lineName:"线路名称",
    lineType:"线路类型",
    publicNetwork:"公网",
    LAN:"局域网",
    minSize:"带宽最小购买量(Mbps)",
    maxSize:"带宽最大购买量(Mbps)",
    doorosModel:"关联线路",
    bandwidth:"带宽",
  },
  eip:{
    eip:"EIP产品"
  },
  images:{
    image:'镜像产品',
    imageType:"镜像类型",
    publicImage:"公共镜像",
    customImage:"自定义镜像",
    osType:"操作系统",
    osTypes:"操作系统分类",
    syncImageProduct:"同步镜像产品"
  },
  bmsCombo:{
    bmsCombo:"BMS关联配置",
    nodeProduct:"节点产品",
    ebsProduct:"块存储产品",
    imageProduct:"镜像产品",
    lineProduct:"线路产品",
    priceUnit:"可够买时间",
    dataDiskMaxNum:"数据盘最大购买个数",
    dataDiskMinSize:"数据盘最小购买容量",
    dataDiskMaxSize:"数据盘最大购买容量",
    systemDiskSize:"系统盘大小",
    dataDiskSize:"数据盘购买容量",
    cpu:"CPU",
    coreNum:"CPU核心数",
    cpuName:"CPU名称",
    memory:"内存",
    ssd:"SSD",
    bmsInfo:"BMS关联信息"
  },
  vpcProduct:{
    vpcProduct:"VPC产品",
    code:"编码",
    doorosModel:"关联产品"
  },
  roles:{
    role:"角色",
    choosePermissions:"选择菜单及动作权限",
  },
  timeRatio:{
    timeRatio:"时间系数",
    unit:"价格单位",
    period:"购买时长",
    ratio:"换算系数",
  },
  user:{
    user:"用户",
    email:"邮箱",
    phone:"联系电话",
    password:"密码",
    balance:"余额",
    updatePassword:"修改密码",
    logout:"登出",
    newPassword:"新密码",
    oldPassword:"旧密码",
    loginFaild:"登陆失败"
  },
  fundflow:{
    chargeNo:"充值编号",
    financialFlow:"财务流水",
    fundflow:"收支记录",
    userNo:"用户编号",
    tradeNo:"交易编号",
    tradeType:"交易类型",
    tradeDirection:"收支类型",
    tradeChannel:"交易渠道",
    oppositeTradeId:"交易渠道流水号",
    currency:"货币类型",
    billId:"订单号/账单号",
    desc:"交易备注",
    billingCycle:"账期",
    dealAmount:"金额",
    balance:"余额",
    oppositeTradeAccount:"交易渠道帐号",
    CNY:"人民币",
    USD:"美元",
    HKD:"港币",
    ALIPAY:"支付宝",
    WECHAT:"微信支付",
    ACCT_CASH:"用户余额",
    BANK:"银行转账",
    OFFLINE_REMIT:"线下转账",
    CREDIT:"信用",
    recharge:"充值",
    refund:"退款",
    consumption:"消费",
    withdrawal:"提现",
    income:"收入",
    expenditure:"支出"
  },
  chargeRecord:{
    chargeRecord:"充值",
    recharge:"充值记录",
    tradeAmount:"金额",
    type:"记录类型",
    inProcess:"处理中",
    recorded:"已入账",
    invalid:"已作废",
    refundLog:"退款记录"
  },
  consumeRecord:{
    month:"月份",
    commodity:"产品明细",
    chargeType:"付费类型",
    orderNo:"订单/账单编号",
    createTime:"创建时间",
    payStatus:"支付状态",
    serviceStartTime:"服务开始时间",
    serviceEndTime:"服务结束时间",
    originalAmount:"原价",
    discountAmount:"优惠金额",
    clearAmount:"抹零金额",
    requirePayAmount:"应付金额",
    cashPayAmount:"现金支付金额",
    couponPayAmount:"代金券支付金额",
    complete:"完成",
    toPaid:"待支付"
  },
  bms:{
    zone:"区域",
    id:"ID",
    name:"名称",
    idName:"ID/名称",
    bms:"BMS",
    ebs:"EBS",
    ip:"IP",
    image:"镜像",
    networkType:"网络类型",
    startTime:"开通时间",
    expiredAt:"到期时间",
    runState:"运行状态",
    stateDetails:"详情",
    network:"网络",
    networkCard:"网卡",
    publicIP:"公网IP地址",
    ipName:"IP名称",
    powerOpt:"开关机",
    setpsd:"密码设置",
    psd:"原始密码",
    setNewPsd:"新设密码",
    clickPowerOn:"点击开机",
    clickPowerOff:"点击关机",
    queryPsd:"查询初始密码",
    initialPsd:"初始密码",
    disconnected:"已断网",
    resourceInfo:"资源信息",
    baseInfo:"基本信息",
    tags:"标签",
    configInfo:"配置信息",
    osType:"操作系统",
    imageType:"镜像类型",
    cpu:"CPU",
    memorySize:"内存大小",
    payInfo:"付费信息",
    renewalMethod:"续费方式",
    ipInfo:"IP信息",
    lineName:"线路名称",
    ebsInfo:"EBS信息",
    bmcKeyCombination:"发送Ctrl+All+Delete组合键",
    sendStr:"发送字符串",
    send:"发送",
    connect:"连接",
    availableBms:"可用BMS",
    recycledBms:"已回收BMS",
    recycled:"已回收",
    recycledTime:"回收时间",
    resourceId:"资源ID",
  },
  disk:{
    diskType:"磁盘类型",
    sizeGB:"容量(GB)",
    hostId:"宿主ID",
    instanceId:"实例ID",
    diskName:"EBS名称",
    size:"容量"
  },
  ip:{
    ipadrr:"IP地址",
    lineProduct:"线路名称",
    hostCode:"宿主产品"
  },
  diskImage:{
    imageProductUuid:"镜像产品UUID",
    snapUuid:"快照UUID",
    instanceId:"实例ID",
    createTime:"创建时间",
    image:"镜像源"
  },
  diskSnap:{
    diskUuid:"磁盘UUID",
    createImage:"创建自定义镜像",
    diskImage:"自定义镜像",
    imageName:"镜像名称",
  },
  order:{
    order:"订单",
    orderNo:"订单编号",
    orderType:"订单类型",
    productType:"产品",
    buy:"新购",
    renew:"续费",
    updateConfig:"配置变更",
    refund:"退款",
    tradeAmount:"实际交易金额",
    amountPayable:"应付金额",
    quantity:"购买数量",
    invalid:"作废",
    toPay:"待支付",
    paid:"已支付",
    payTime:"支付时间",
    period:"购买时长",
    orderDetails:"订单详情",
    orderInfo:"基础信息",
    bmsInfo:"BMS信息",
    ebsInfo:"EBS信息",
    imageInfo:"镜像信息",
    networkInfo:"网络信息",
    oldSize:"原始大小",
    newSize:"扩容大小",
    couponAmount:"优惠券抵扣",
    discountAmount:"等级折扣金额",
    vpcInfo:"VPC信息",
    vpcName:"VPC名称"
  },
  customer:{
    customer:"客户",
    regChannel:"注册渠道",
    webReg:"网站注册",
    adminAdd:"管理员添加",
    gradeName:"等级名称",
    bmsRate:"BMS价格系数",
    ebsRate:"EBS价格系数",
    linesRate:"线路价格系数",
    ipRate:"IP价格系数",
    natRate:"NAT价格系数",
    grade:"等级",
    customerGrade:"客户等级",
    managerID:"客户经理ID",
    certType:"认证类型",
    personal:"个人",
    business:"企业/政府",
    companyName:"企业名称",
    idCardName:"身份证名称",
    idCardNO:"身份证号码",
    idCardPic:"身份证照片",
    licenseNO:"营业执照",
    licensePic:"营业执照照片",
    userID:"用户ID",
    product:"已购产品",
    bms:"裸金属服务器",
    ebs:"块存储云盘",
    eip:"IP",
    diskSnap:"云盘快照",
    diskImage:"自定义镜像",
    recoveredResources:"已回收资源",
    loginLogs:"登录日志",
    remoteIp:"登录IP",
    userAgent:"用户代理",
    userNo:"用户编号",
    loginTime:"登录时间",
    apiLogs:"API请求日志",
    level:"日志级别",
    traceID:"追踪ID",
    tag:"标签",
    version:"版本",
    message:"消息",
    method:"请求方式",
    uri:"URI",
    data:"内容",
    errorStack:"错误",
    createTime:"创建时间"
  },
  audit:{
    auditType:"认证类型",
    chooseAuditType:"请选择认证类型",
    auditTitle:"实名认证决定了账号归属，请根据实际情况进行实名认证",
    person:"个人认证",
    business:"企业认证",
    personDes:"适用于个人用户，账户归属于个人",
    businessDes:"适用于企业/政府（含企业、政府、事业单位、团体、组织）,账号归属于企业",
    auditBtn:"开始认证",
    userInfo:"基本信息",
    dataUpload:"资料上传",
    dataUploadDes:"请提供证件照片、图片清晰，最大支持20M",
    userID:"用户ID",
    idCardName:"身份证名称",
    idCardNO:"身份证号码",
    idCardPic:"身份证正反面照片",
    details:"认证详情",
    certificatesList:"证件列表",
    auditData:"认证审核数据"
  },
  paySetting:{
    paySetting:"支付配置",
    tradeChannel:"支付渠道",
    tradeType:"支付类型",
    ALIPAY:"支付宝",
    WECHAT:"微信支付",
    BANK:"银行转账",
    AlipayGlobal:"国际支付宝",
    PAYPAL:"Paypal",
    onlinePayment:"在线支付",
    offlineTransfer:"线下转账",
    keyName:"字段名称",
    valueName:"字段值",
    appId:"App ID",
    productCode:"产品编码",
    privateKey:"私钥",
    publicKey:"公钥",
    signType:"签名类型",
    mchId:"商户号",
    appKey:"App Key",
    appCert:"证书信息",
    apiKey:"Api Key",
    add:"新增自定义字段",
    icon:"图标"
  },
  smsConfig:{
    smsConfig:"短信配置",
    accessKey:"AccessKey ID",
    accessSecret:"Access Secret",
    provider:"服务商",
    signName:"短信签名",
    see:"查看",
    hidden:"隐藏",
    SmsTmpConfig:"短信模板配置",
    code:"模板编码",
    activeTime:"有效时间",
    content:"内容",
    templateCode:"短信编码",
    SMSVerifyKeyPrefix:"sms_verify_key_",
    CommonVerifyCode:"通用验证码",
    UserRegister:"用户注册",
    UserLogin:"用户登录",
    UserRetrievePassword:"重置密码",
    UserUpdatePhone:"更新绑定手机",
    UserUpdateEmail:"更新绑定邮箱",
    UserBindPhone:"用户绑定手机",
    HostReinstallOS:"重装系统",
    HostPasswordRest:"重置服务器密码",
    ChargeSuccess:"充值成功",
    InstanceWillExpired:"服务器即将过期",
    InsufficientBalance:"账户余额不足",
    ServiceSuspended:"欠费停机",
    RenewSuccess:"续费成功",
    aliyun:"阿里云"
  },
  emailConfig:{
    emailConfig:"邮件配置",
    fromAddr:"邮箱地址",
    host:"服务器地址",
    port:"端口",
    emailTmpConfig:"邮件模板配置",
    code:"模板编码",
    subject:"主题",
    content:"内容",
    name:"名称",
    userName:"用户名",
    password:"密码",
  },
  ticket:{
    ticket:"工单",
    ticketType:"工单分类",
    code:"类型编号",
    name:"名称",
    creator:"创建者",
    ticketNo:"工单编号",
    content:"内容",
    phone:"手机号",
    email:"邮箱",
    is_user_deleted:"用户是否删除",
    progress:"处理进度",
    wait_feedback:"待用户反馈",
    wait_confirm:"待用户确认",
    assigned:"已分派专员处理",
    dealing:"处理中",
    confirmed:"待用户评价",
    score:"用户评价",
    attach_list:"附件列表",
    msg_list:"消息列表",
    replay_msg:"回复信息",
    uploadAttach:"上传附件",
    startProcessing:"开始处理",
    processing:"处理中",
    processingComplete:"处理完成",
    ticketDetails:"工单详情",
    admin:"管理员",
    replay:"回复",
    deal:"处理",
    ticketMsg:"工单消息",
  },
  innerMsg:{
    innerMsg:"站内信",
    content:"内容",
    isUserDeleted:"用户删除",
    isDeleted:"已删除",
    read:"已读",
    unRead:"未读",
    typeCode:"分类编号",
    innerMsgType:"消息分类",
    title:"标题",
  },
  siteConfig:{
    pageContent:"页面",
    pageSetting:"页面配置",
    addPageSetting:"新增页面配置",
    domain:"域名",
    title:"站点标题",
    content:"站点描述",
    logoSetting:"Logo配置",
    contract:"联系方式",
    contractSetting:"联系方式配置",
    currencySetting:"站点货币配置",
    langSetting:"站点默认语言配置",
    phone:"电话",
    email:"邮箱",
    qq:"QQ",
    address:"地址",
    analyticCode:"站长统计代码",
    currency:"货币",
    language:"语言",
    logo:"LOGO",
    miniLogo:"MiniLOGO",
    titleLogo:"TitleLOGO",
    example:"示例",
    alipayCertufyCfg:"支付宝实名认证应用",
    alipayCertufyCfgSetting:"支付宝实名认证应用配置",
    appId:"AppID",
    signType:"签名类型",
    privateKey:"应用私钥",
    publicKey:"支付宝公钥",
    bizCode:"认证场景",
    FACE:'多因子人脸认证',
    CERT_PHOTO_FACE:'多因子证照和人脸认证',
    CERT_PHOTO:'多因子证照认证',
    SMART_FACE:'多因子快捷认证',
    logoNotice:"提醒：图片需小于2M，格式仅支持jpg和png。图片不能大于220px * 28px, 背景色透明",
    miniLogoNotice:"提醒：菜单收缩后的logo，图片需小于2M，格式仅支持jpg和png。图片不能大于30px * 25px, 背景色透明",
    titleLogoNotice:"提醒：页面标签图标，图片需小于2M，格式仅支持jpg和png。图片不能大于24px * 24px, 背景色透明",
    agreement:"协议类型",
    agreementDomain:"完整域名"
  },
  coupons:{
    coupon:"优惠券",
    couponLog:"优惠券领取记录",
    fullReductionCoupon:"满减券",
    preferentialCoupon:"优惠券",
    discountCoupon:"折扣券",
    online:"上线",
    offline:"下线",
    title:"标题",
    minus:"优惠金额",
    rate:"折扣",
    fullMoney:"满减额",
    grantNum:"发券数量",
    receivedNum:"已领取数量",
    memo:"规格描述",
    type:"类型",
    limitNum:"单人限领",
    startTime:"开始时间",
    endTime:"结束时间",
    notUsed:"未使用",
    used:"已使用",
    expired:"已过期",
    couponTitle:"优惠券标题",
    code:"券码",
    userNo:"用户编号",
    couponId:"优惠券ID",
    expiredTime:"过期时间",
    orderNo:"核销订单号",
    usedTime:"核销时间",
    couponStatus:"优惠券状态",
    couponTime:"优惠券使用时间",
    rateUnit:"折扣系数",
    discountDes:"满{fullMoney}减{minus}"
  },
  message:{
    searchByKey:"输入{key}搜索",
    inputMsg:"请输入{msg}",
    selectMsg:"请选择{msg}",
    uploadMsg:"请上传{msg}",
    confirmDelMsg:"确定要删除{msg}?",
    addSomeSuccess:"新增{msg}成功",
    addSomeFail:"新增{msg}失败",
    setSomeSuccess:"设置{msg}成功",
    setSomeFail:"设置{msg}失败",
    updateSomeSuccess:"更新{msg}成功",
    updateSomeFail:"更新{msg}失败",
    delSomeSuccess:"删除{msg}成功",
    delSomeFail:"删除{msg}失败",
    confirmDelSome:"确认删除{msg}",
    confirmRecord:"确认入账{msg}",
    confirmInvalid:"确认作废{msg}",
    confirmDestroy:"确认销毁",
    getDataFail:"数据获取失败",
    optionSuccess:"操作成功",
    optionFail:"操作失败",
    copySuccess:"复制成功",
    copyFail:"复制失败",
    syncSomeSuccess:"同步{msg}成功",
    syncSomeFail:"同步{msg}失败",
    someOptionSuccess:"{msg}成功",
    someOptionFail:"{msg}失败",
    confirmOption:"确认{msg}",
    settingSuccess:"配置{msg}成功",
    bmsInSomeState:"当前BMS正在{msg}",
    isLt20M:"最大支持20M",
    limitType:"文件格式仅支持image/jpg,.jpg,image/jpeg,.jpeg,image/png,.png,image/bmp,.bmp,,image/gif,.gif",
    noPower:"您无权访问改页面",
    contractAdmin:"请联系管理员",
    noPage:"页面走丢了",
    getBmsInfoFail:"获取服务器信息失败",
    cantOpenBmc:"当前服务器不可连接BMC",
    powerOnDesc:"服务器在关机状态下才能执行开机操作",
    powerOffDesc:"服务器为混裸,且在运行状态下才能执行关机操作",
    restartDesc:"服务器为混裸,且在开机状态下才能执行重启操作",
  },
  unit:{
    year:"年",
    month:"月",
    week:"周",
    day:"天",
    hour:"小时",
    yuan:"元",
    gb:"GB",
    core:"核",
    mbps:"Mbps",
    second:"秒",
    one:"个"
  },
  certification:{
    audit:"待审核",
    auditSuccess:"审核通过",
    reject:"被驳回"
  },
  productCode:{
    bms:"BMS产品",
    ebs:"块存储产品",
    eip:"IP产品",
    lines:"线路产品",
    nat:"NAT产品",
    nas:"NAS产品",
    vpc:"VPC产品"
  },
  networkType:{
    classic:"经典网络",
    proprietary:"专有网路",
    LAN:"局域网"
  },
  bmsState:{
    HostApplySuccess:"资源申请成功",//1
    HostPending:"创建中",//2
    HostStopped:"关机",//3
    HostStarting:"启动中",//4
    HostRunning:"运行中",//5
    HostStopping:"关机中",//6
    HostRestarting:"重启中",//7
    HostUnusual:"异常",//8
    HostReinstalling:"重装中",//9
    HostMigrating:"迁移中",//10
    HostMigrateRestoring:"迁移还原中",//11
    HostSgOpting:"安全组操作中",//12
    HostDiskOpting:"磁盘操作中",//13
    // HostNetworkOpting:"网络操作中(开网/断网)",
    HostNetworkOpting:"网络操作中",//14
    HostPwdResetting:"密码重置中",//15
    HostFireWallOpting:"防火墙配置更新中",//16
    HostDestroying:"销毁中",//17
    HostDestroyed:"已销毁",//18
  },
  stateDetail:{
    HostDataDiskCreate:"数据盘创建",
    HostDataDiskCreateFailed:"数据盘创建失败",
    HostSysImgCopy:"系统盘创建",
    HostSysImgCopyFailed:"系统盘创建失败",
    HostBindLink:"链路绑定",
    HostBindLinkFailed:"链路绑定失败",
    HostSysInstall:"系统安装中",
    HostSysInstallFailed:"系统安装失败",
    HostChainCfg:"链路配置",
    HostChainCfgFailed:"链路配置失败",
    HostLinkIpRuleAdd:"添加IP规则",
    HostLinkIpRuleAddFailed:"添加IP规则失败",
    HostNetworkCfgHostIpBind:"绑定IP",
    HostNetworkCfgHostIpBindFailed:"绑定IP失败",
    HostNetworkCfgShareGroupCreate:"创建共享组",
    HostNetworkCfgShareGroupFailed:"创建共享组失败",
    HostNetworkCfgAddIPToShareGroup:"IP加入共享组",
    HostNetworkCfgAddIPToShareGroupFailed:"IP加入共享组失败",
    HostDestroyRemoveFromSg:"移除安全组",
    HostDestroyRemoveFromSgFailed:"移除安全组失败",
    HostDestroyCancelIpBwLimit:"取消IP限速",
    HostDestroyCancelIpBwLimitFailed:"取消IP限速失败",
    HostDestroyIpRemoveFromShareGroup:"IP移除共享组",
    HostDestroyIpRemoveFromShareGroupFailed:"IP移除共享组失败",
    HostDestroyShareGroup:"共享组销毁",
    HostDestroyShareGroupFailed:"共享组销毁失败",
    HostDestroyHostIpUnbind:"解绑IP",
    HostDestroyHostIpUnbindFailed:'解绑IP失败',
    HostDestroyIpRuleDel:"删除IP规则",
    HostDestroyIpRuleDelFailed:"删除IP规则失败",
    HostDestroyLink:"链路销毁",
    HostDestroyLinkFailed:"链路销毁失败",
    HostDestroyKVMNodeDestroy:"节点销毁(混祼节点)",
    HostDestroyKVMNodeDestroyFailed:"节点销毁失败(混祼节点)",
    HostDestroyLinkUnbind:"链路解绑",
    HostDestroyLinkUnBindFailed:"链路解绑失败",
    HostDestroyResource:"资源释放",
    HostDestroyResourceFailed:"资源释放失败",
    HostReinstallImgApply:"镜像申请",
    HostReinstallImgApplyFailed:"镜像申请失败",
    HostReinstallImgCopy:"系统盘创建",
    HostReinstallImgCopyFailed:"系统盘创建失败",
    HostReinstallReCreate:"重装系统",
    HostReinstallReCreateFailed:"重装系统失败",
    HostDiskOptAttach:"磁盘挂载",
    HostDiskOptAttachFailed:"磁盘挂载失败",
    HostDiskOptDetach:"磁盘卸载",
    HostDiskOptDetachFailed:"磁盘卸载失败",
    HostMigrateApplyNode:"申请节点",
    HostMigrateApplyNodeFailed:"申请节点失败",
    HostMigrateCfg:"迁移配置",
    HostMigrateCfgFailed:"迁移配置失败",
    HostMigrateRestoreCfg:"迁移还原配置",
    HostMigrateRestoreCfgFailed:"迁移还原配置失败",
    HostMigrateRestoreNodeDestroy:"当前节点销毁",
    HostMigrateRestoreNodeDestroyFailed:"当前节点销毁失败",
  },
  runState:{
    nodeRunning:"正常",
    nodeStopped:"关机",
    bideUnusual:"主机无法连通"
  },
  isCertified:{
    isCertified:"已实名",
    noCertified:"未实名"
  },
  ipState:{
    Unbind:"未绑定",
    Bind:"已绑定",
    Binding:"绑定中",
    Unbundling:"解绑中",
    JoinSharingGroup:"加入共享组",
    MoveOutOfSharingGroup:"移出共享组",
    SetTheSpeedLimit:"设置限速",
    CancelTheSpeedLimit:"取消限速",
    Destroyed:"已销毁",
  },
  ebsState:{
    Creating:"创建中",
    Unmounted:"未挂载",
    Mounted:"已挂载",
    Mounting:"挂载中",
    Unloading:"卸载中",
    Expanding:"扩容中",
    SnapshotCreating:"快照创建中",
    RollingBack:"回滚中",
    SnapshotCleaning:"快照清理中",
    DestructionInProgress:"销毁中",
    Destroyed:"已销毁",
  },
  diskImageState:{
    Creating:"创建中",
    Available:"可用",
    DestructionInProgress:"销毁中",
    Destroyed:"已销毁",
  },
  diskSnapState:{
    Creating:"创建中",
    Cloning:"克隆中",
    Available:"可用",
    DestructionInProgress:"销毁中",
    Destroyed:"已销毁",
  },
  vpcState:{
    VpcCreating:"创建中",
    VpcAvailable:"可用",
    VpcUpgrading:"升级中",
    VpcDownlinkBandwidthUpdateInProgress:"下行带宽更新中",
    VpcDestroying:"销魂中",
    VpcHostDestroyed:"已销毁",
  },
  ...vexZhCN,
  ...zhLang
}

export default cn
