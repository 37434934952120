import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()

  document.title = getPageTitle(to.meta.title)

  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // next({ path: '/' })
      next()
      NProgress.done() 
    }
    //  else if(to.path === '/'){

    //     next({ path: '/' })
    // }
    else {
        if(store.getters.routes.length || to.name !== null){
          next()
        }else{

          const { list } = await store.dispatch('user/getMenuTree')
              const accessRoutes = await store.dispatch('user/generateRoutes',list)
              //add accessRoutes
              router.addRoutes(accessRoutes)
              next({ ...to, replace: true })
        }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
