const ADMIN = '/api'
// const ADMIN = ''

const APICONFIG = {
  //dashboard
  dashboardInfo:`${ADMIN}/v1/admin/dashboard`,//控制台信息
  dashboardMonthSales:`${ADMIN}/v1/admin/dashboard/sales-by-month`,//销售统计(按月)
  dashboardDaySales:`${ADMIN}/v1/admin/dashboard/sales-by-day`,//销售统计(按天)
  dashboardMonthRefund:`${ADMIN}/v1/admin/dashboard/refund-by-month`,//退款统计(按月)
  dashboardDayRefund:`${ADMIN}/v1/admin/dashboard/refund-by-day`,//退款统计(按天)
  dashboardMonthUserReg:`${ADMIN}/v1/admin/dashboard/user-reg-by-month`,//用户增加数量统计(按月)
  dashboardDayUserReg:`${ADMIN}/v1/admin/dashboard/user-reg-by-day`,//用户增加数量统计(按天)

  //user
  login:`${ADMIN}/v1/admin/login`,//用户登录
  userInfo:`${ADMIN}/v1/admin/current/user`,//当前用户信息
  currentPassword:`${ADMIN}/v1/admin/current/password`,//更新个人密码
  menuTree:`${ADMIN}/v1/admin/current/menutree`,//当前用户菜单树
  menuList:`${ADMIN}/v1/admin/menus.tree`,//查询菜单树
  menus:`${ADMIN}/v1/admin/menus`,//菜单管理
  basePriceUnit:`${ADMIN}/v1/admin/product/base-price-unit`,//查询基础价格单位

  dataCenter:`${ADMIN}/v1/admin/data-centers`,//查询数据中心数据
  regions:`${ADMIN}/v1/admin/regions`,//地域数据
  ebsProduct:`${ADMIN}/v1/admin/product/ebs`,//块存储产品
  ebsProductPrice:`${ADMIN}/v1/admin/product/ebs-prices`,//块存储产品价格
  nodeProduct:`${ADMIN}/v1/admin/product/node`,//裸金属节点
  productTags:`${ADMIN}/v1/admin/product/tags`,//产品标签
  nodeProductPrice:`${ADMIN}/v1/admin/product/node-prices`,//裸金属节点价格
  lineProduct:`${ADMIN}/v1/admin/product/lines`,//线路产品
  lineProductPrice:`${ADMIN}/v1/admin/product/lines-prices`,//线路产品价格
  eipProduct:`${ADMIN}/v1/admin/product/eip`,//IP产品
  images:`${ADMIN}/v1/admin/product/images`,//镜像产品
  imagesSync:`${ADMIN}/v1/admin/product/images/sync`,//镜像同步
  osType:`${ADMIN}/v1/admin/product/os-types`,//操作系统分类
  bmsCombo:`${ADMIN}/v1/admin/product/bms-combos`,//裸金属套餐
  nodeCPU:`${ADMIN}/v1/admin/product/node-cpu`,//CPU规格
  nodeGPU:`${ADMIN}/v1/admin/product/node-gpu`,//GPU规格
  nodeHardDisk:`${ADMIN}/v1/admin/product/node-hdd`,//硬盘规格
  nodeNic:`${ADMIN}/v1/admin/product/node-nic`,//网卡规格
  nodeRam:`${ADMIN}/v1/admin/product/node-ram`,//内存规格
  priceUnits:`${ADMIN}/v1/admin/product/price-units`,//購買時間
  roles:`${ADMIN}/v1/admin/roles`,//角色
  user:`${ADMIN}/v1/admin/users`,//用户
  vpcProduct:`${ADMIN}/v1/admin/product/vpc`,//vpc产品

  //dooros
  doorosDataCenter:`${ADMIN}/v1/admin/resource/data-centers`,//dooros数据中心列表
  doorosPools:`${ADMIN}/v1/admin/resource/pools`,//dooros存储
  nodeResource:`${ADMIN}/v1/admin/resource/nodes`,//所有节点产品
  lineResource:`${ADMIN}/v1/admin/resource/lines`,//线路产品
  vpcResource:`${ADMIN}/v1/admin/resource/vpc`,//VPC产品


  //finance
  fundflows:`${ADMIN}/v1/admin/fund-flows`,//收支记录
  chargeRecord:`${ADMIN}/v1/admin/charge-records`,//充值记录
  chargeCommit:`${ADMIN}/v1/admin/charge-records/charge-commit`,//充值入账确认
  refundCommit:`${ADMIN}/v1/admin/charge-records/refund-commit`,//退款入账确认
  consumeRecord:`${ADMIN}/v1/admin/consume-records`,//消费记录



  //business
  bms:`${ADMIN}/v1/admin/instance/bms`,//裸金属服务器
  bmsState:`${ADMIN}/v1/admin/instance/bms-state`,//状态查询
  bmsPwd:`${ADMIN}/v1/admin/instance/bms-pwd`,//查询裸金属服务器初始密码
  bmsAutoRenew:`${ADMIN}/v1/admin/instance/bms-auto-renew`,//bms自動續費
  bmsBmc:`${ADMIN}/v1/admin/instance/bms-bmc`,//BMC地址查詢
  recycledBms:`${ADMIN}/v1/admin/instance/host/info/snap`,//已回收服务器
  disk:`${ADMIN}/v1/admin/instance/disk`,//磁盘
  diskState:`${ADMIN}/v1/admin/instance/disk-state`,//磁盘状态查询
  eip:`${ADMIN}/v1/admin/instance/eip`,//IP
  eipState:`${ADMIN}/v1/admin/instance/eip-state`,//IP状态查询
  vpc:`${ADMIN}/v1/admin/instance/vpc`,//VPC
  vpcState:`${ADMIN}/v1/admin/instance/vpc-state`,//vpc状态查询
  diskImage:`${ADMIN}/v1/admin/instance/disk-image`,//自定义镜像
  diskImageState:`${ADMIN}/v1/admin/instance/disk-image-state`,//磁盘快照状态查询
  diskSnap:`${ADMIN}/v1/admin/instance/disk-snap`,//磁盘快照
  diskSnapState:`${ADMIN}/v1/admin/instance/disk-snap-state`,//磁盘快照状态查询
  diskSnapImage:`${ADMIN}/v1/admin/instance/disk-snap-image`,//系统盘快照创建自定义镜像


  //setting
  payConfig:`${ADMIN}/v1/admin/pay-configs`,//支付配置
  smsConfig:`${ADMIN}/v1/admin/sms`,//短信配置
  smsTmpConfig:`${ADMIN}/v1/admin/sms-tpl`,//短信模板配置
  emailConfig:`${ADMIN}/v1/admin/mail`,//邮箱配置
  emailTmpConfig:`${ADMIN}/v1/admin/mail-tpl`,//邮箱模板配置
  ticketType:`${ADMIN}/v1/admin/ticket-types`,//工单分类
  ticket:`${ADMIN}/v1/admin/tickets`,//工单
  ticketMsg:`${ADMIN}/v1/admin/ticket-msgs`,//工单消息
  innerMsg:`${ADMIN}/v1/admin/inner-msgs`,//站内信
  innerMsgType:`${ADMIN}/v1/admin/inner-msg-types`,//站内信分类
  siteConfig:`${ADMIN}/v1/admin/site-configs`,//站点配置

  //user
  customer:`${ADMIN}/v1/admin/mall/users`,//客户管理
  customerGrade:`${ADMIN}/v1/admin/mall/user-grades`,//等級配置
  userCertifications:`${ADMIN}/v1/admin/mall/user-certifications`,//用户认证
  loginLog:`${ADMIN}/v1/admin/login-logs`,//登陸日志
  apiLogs:`${ADMIN}/v1/admin/loggers`,//api请求日志
  uploadFile:`${ADMIN}/v1/admin/mall/user-certification-upload`,//文件上傳
  uploadTicketFile:`${ADMIN}/v1/admin/tickets/file-upload`,//工单文件上傳
  uploadSiteConfigFile:`${ADMIN}/v1/admin/site-configs/file-upload`,//站点配置文件上传

  //order
  order:`${ADMIN}/v1/admin/orders`,//订单
  orderSingle:`${ADMIN}/v1/admin/order`,//订单

  //coupon
  couponLogs:`${ADMIN}/v1/admin/order-coupon-logs`,//优惠券领取记录
  coupons:`${ADMIN}/v1/admin/order-coupons`,//优惠券
}

export default {
  ...APICONFIG,
}