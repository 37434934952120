const state = {
  networkTypeList: [
    {
      value:1,
      label:'classic',
    },
    {
      value:2,
      label:'proprietary',
    },
    {
      value:3,
      label:'LAN',
    },
  ],
  networkTypeTable:{
    1:'classic',
    2:'proprietary',
    3:'LAN'
  },
  imageType:[
    {
      value:1,
      label:'publicImage'
    },{
      value:2,
      label:'customImage'
    }
  ],
  bmsStateTable:{
    1:'HostApplySuccess',
    2:'HostPending',
    3:'HostStopped',
    4:'HostStarting',
    5:'HostRunning',
    6:'HostStopping',
    7:'HostRestarting',
    8:'HostUnusual',
    9:'HostReinstalling',
    10:'HostMigrating',
    11:'HostMigrateRestoring',
    12:'HostSgOpting',
    13:'HostDiskOpting',
    14:'HostNetworkOpting',
    15:'HostPwdResetting',
    16:'HostFireWallOpting',
    17:'HostDestroying',
    18:'HostDestroyed'
  },
  ipStateTable:{
    82:'Unbind',
    83:'Bind',
    84:'Binding',
    85:'Unbundling',
    86:'JoinSharingGroup',
    87:'MoveOutOfSharingGroup',
    88:'SetTheSpeedLimit',
    89:'CancelTheSpeedLimit',
    90:'Destroyed',
  },
  ebsStateTable:{
    21:"Creating",
    22:"Unmounted",
    23:"Mounted",
    24:"Mounting",
    25:"Unloading",
    26:"Expanding",
    27:"SnapshotCreating",
    28:"RollingBack",
    29:"SnapshotCleaning",
    30:"DestructionInProgress",
    31:"Destroyed",
  },
  diskImageStateTable:{
    61:'Creating',
    62:'Available',
    63:'DestructionInProgress',
    64:'Destroyed'
  },
  diskSnapStateTable:{
    41:'Creating',
    42:'Available',
    43:'Cloning',
    44:'DestructionInProgress',
    45:'Destroyed',
  },
  runStateTable:{
    0:'nodeRunning',
    1:'nodeStopped',
    2:'bideUnusual'
  },
  stateDetailTable:{
    1:'HostDataDiskCreate',
    2:'HostDataDiskCreateFailed',
    3:'HostSysImgCopy',
    4:'HostSysImgCopyFailed',
    5:'HostBindLink',
    6:'HostBindLinkFailed',
    7:'HostSysInstall',
    8:'HostSysInstallFailed',
    9:'HostChainCfg',
    10:'HostChainCfgFailed',
    11:'HostLinkIpRuleAdd',
    12:'HostLinkIpRuleAddFailed',
    13:'HostNetworkCfgHostIpBind',
    14:'HostNetworkCfgHostIpBindFailed',
    15:'HostNetworkCfgShareGroupCreate',
    16:'HostNetworkCfgShareGroupFailed',
    17:'HostNetworkCfgAddIPToShareGroup',
    18:'HostNetworkCfgAddIPToShareGroupFailed',
    40:'HostDestroyRemoveFromSg',
    41:'HostDestroyRemoveFromSgFailed',
    42:'HostDestroyCancelIpBwLimit',
    43:'HostDestroyCancelIpBwLimitFailed',
    44:'HostDestroyIpRemoveFromShareGroup',
    45:'HostDestroyIpRemoveFromShareGroupFailed',
    46:'HostDestroyShareGroup',
    47:'HostDestroyShareGroupFailed',
    48:'HostDestroyHostIpUnbind',
    49:'HostDestroyHostIpUnbindFailed',
    50:'HostDestroyIpRuleDel',
    51:'HostDestroyIpRuleDelFailed',
    52:'HostDestroyLink',
    53:'HostDestroyLinkFailed',
    54:'HostDestroyKVMNodeDestroy',
    55:'HostDestroyKVMNodeDestroyFailed',
    56:'HostDestroyLinkUnbind',
    57:'HostDestroyLinkUnBindFailed',
    58:'HostDestroyResource',
    59:'HostDestroyResourceFailed',
    80:'HostReinstallImgApply',
    81:'HostReinstallImgApplyFailed',
    82:'HostReinstallImgCopy',
    83:'HostReinstallImgCopyFailed',
    84:'HostReinstallReCreate',
    85:'HostReinstallReCreateFailed',
    90:'HostDiskOptAttach',
    91:'HostDiskOptAttachFailed',
    92:'HostDiskOptDetach',
    93:'HostDiskOptDetachFailed',
    100:'HostMigrateApplyNode',
    101:'HostMigrateApplyNodeFailed',
    102:'HostMigrateCfg',
    103:'HostMigrateCfgFailed',
    104:'HostMigrateRestoreCfg',
    105:'HostMigrateRestoreCfgFailed',
    106:'HostMigrateRestoreNodeDestroy',
    107:'HostMigrateRestoreNodeDestroyFailed'
  },
  vpcStateTable:{
    141:"VpcCreating",
    142:"VpcAvailable",
    143:"VpcUpgrading",
    144:"VpcDownlinkBandwidthUpdateInProgress",
    145:"VpcDestroying",
    146:"VpcHostDestroyed"
  }
}

export default {
  namespaced: true,
  state,
}

