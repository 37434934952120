
import Layout from '@/layout'

const innerMsgRouter = {
  path: '/message',
  component: Layout,
  redirect: '/message/innerMsg',
  code:'NoticeMangment',
  name: 'NoticeMangment',
  meta: {
    title: 'NoticeMangment',
    icon: 'message'
  },
  children: [
    {
      path: 'innerMsg',
      component: () => import('@/views/innerMsg/index'),
      name: 'InnerMsg',
      meta: { title: 'InnerMsg' }
    },
    {
      path: 'innerMsgType',
      component: () => import('@/views/innerMsgType/index'),
      name: 'InnerMsgType',
      meta: { title: 'InnerMsgType' }
    },
    // {
    //   path: 'consumeRecord',
    //   component: () => import('@/views/consumeRecord/index'),
    //   name: 'ConsumeRecord',
    //   meta: { title: 'ConsumeRecord' }
    // },
  ]
}

export default innerMsgRouter