
import Layout from '@/layout'

const productRouter = {
  path: '/',
  component: Layout,
  redirect: '/product/dataCenter',
  code:'ProductMangemnt',
  name: 'ProductMangemnt',
  meta: {
    title: 'ProductMangemnt',
    icon: 'product'
  },
  children: [
    {
      path: '/product/dataCenter',
      component: () => import('@/views/dataCenter/index'),
      name: 'DataCenter',
      meta: { title: 'DataCenter' }
    },
    {
      path: '/product/ebs',
      component: () => import('@/views/ebs/index'),
      name: 'EbsProduct',
      meta: { title: 'EbsProduct' },
      children: [
      ]
    }, {
      path: '/product/ebs/price',
      component: () => import('@/views/ebs/price/index'),
      name: 'EbsPrice',
      meta: { title: 'EbsPrice' }
    },
    {
      path: '/product/bms',
      component: () => import('@/views/bmsNode/list/index'),
      name: 'BmsProduct',
      meta: { title: 'BmsProduct' },
    }, 
    {
      path: '/product/bms/specificationsManage',
      redirect: '/product/bms',
      // component: '',
      component: () => import('@/views/bmsNode/index'),
      name: 'BmsProduct',
      meta: { title: 'BmsProduct' },
      children: [
        {
          path: '/',
          component: () => import('@/views/bmsNode/list/specificationsManage'),
          name: 'SpecificationsManage',
          meta: { title: 'SpecificationsManage' },
        },
        // {
        //   path: '/ticket/list/ticketDetails',
        //   component: () => import('@/views/ticket/ticketDetails/index'),
        //   name: 'TicketDetails',
        //   meta: { title: 'TicketDetails' }
        // },
      ] 
    }, 
    // {
    //   path: '/product/bms/specificationsManage',
    //   component: () => import('@/views/bmsNode/list/specificationsManage'),
    //   name: 'SpecificationsManage',
    //   meta: { title: 'SpecificationsManage' },
    // }, 
    {
      path: '/product/lines',
      component: () => import('@/views/lines/index'),
      name: 'LinesProduct',
      meta: { title: 'LinesProduct' },
    }, 
    {
      path: '/product/eip',
      component: () => import('@/views/EIP/index'),
      name: 'EipProduct',
      meta: { title: 'EipProduct' },
    }, 
    {
      path: '/product/images',
      component: () => import('@/views/images/index'),
      name: 'ImagesProduct',
      meta: { title: 'ImagesProduct' },
    }, 
    {
      path: '/product/bms-combo',
      component: () => import('@/views/bmsCombo/index'),
      name: 'BmsCombo',
      meta: { title: 'BmsCombo' },
    }, 
    {
      path: '/product/time',
      component: () => import('@/views/timeRatio/index'),
      name: 'TimeRatio',
      meta: { title: 'TimeRatio' },
    }, 
    {
      path: '/product/vpc',
      component: () => import('@/views/vpcProduct/index'),
      name: 'VpcProduct',
      meta: { title: 'VpcProduct' },
    }, 
  ]
}

export default productRouter