import request from '@/utils/request'
import APICONFIG from '@/utils/api'
import { stringify } from 'qs';
const { dataCenter, doorosDataCenter, regions, basePriceUnit } = APICONFIG

/**
 * @desc 获取当前用户数据中心
 * @param {Object} params
 */
export function getDataCenterService(params) {
  return request({
    url:`${dataCenter}?${stringify(params)}`,
    method:'get',
  })
}

/**
 * @desc dooros数据中心列表
 */
export function getDoorOSDataCenterService() {
  return request({
    url:doorosDataCenter,
    method:'get',
  })
}

/**
 * 获取地域数据
 * @param {Object} params
 */
export function getRegionsService(params) {
  return request({
    url:`${regions}?${stringify(params)}`,
    method:'get',
  })
}


/**
 * 查询基础价格单位
 */
export function getBasePriceUnitService() {
  return request({
    url:basePriceUnit,
    method:'get',
  })
}