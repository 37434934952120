const state = {
  nodeTypeList: [
    {
      value:1,
      label:'pure',
    },
    {
      value:2,
      label:'mix',
    },
  ],
  lineTypeTable: {
    1:'publicNetwork',
    2:'LAN'
  },
  nodeTypeTable:{
    1:'pure',
    2:'mix',
    3:'virtualMachine'
  },
  productCodeList:[
    {
      value:'bms',
      label:'bms',
    },{
      value:'ebs',
      label:'ebs',
    },{
      value:'eip',
      label:'eip',
    },{
      value:'lines',
      label:'lines',
    },{
      value:'vpc',
      label:'vpc',
    },{
      value:'nas',
      label:'nas',
    },
  ]
}

export default {
  namespaced: true,
  state,
}

