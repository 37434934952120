<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />

    <div class="right-menu">
      <template v-if="device!=='mobile'">
        <!-- <search id="header-search" class="right-menu-item" /> -->

        <!-- <error-log class="errLog-container right-menu-item hover-effect" /> -->

        <!-- <screenfull id="screenfull" class="right-menu-item hover-effect" /> -->

        <!-- <el-tooltip content="Global Size" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->

      </template>

      <!-- <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click" @command="changeLang">
        <div class="avatar-wrapper">
          <svg-icon icon-class="language" />
        </div>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command='en-us'>English</el-dropdown-item>
            <el-dropdown-item command='zh-cn'>Chinese</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <svg-icon icon-class="user" />
          <span class="user-name">{{name}}</span>
          <!-- <img :src="avatar+'?imageView2/1/w/80/h/80'" class="user-avatar"> -->
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <router-link to="/profile/index">
            <el-dropdown-item>Profile</el-dropdown-item>
          </router-link> -->
          <!-- <router-link @click.native="handleOpenModal"> -->
            <el-dropdown-item @click.native="handleOpenModal">{{$t('user.updatePassword')}}</el-dropdown-item>
          <!-- </router-link> -->
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">{{$t('user.logout')}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    
  <vxe-modal ref="modal" v-model="updatePasswordVisible" :title="$t('user.updatePassword')" show-zoom resize style="z-index:102">
    <template>
      <el-form :model="formData" :rules="rules" ref="updatePasswordForm" label-width="100px" class="demo-ruleForm">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form-item :label="$t('user.oldPassword')" prop="old_password">
              <el-input v-model="formData.old_password" controls-position="right" :placeholder="$t('message.inputMsg',{msg:$t('user.oldPassword')})"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item :label="$t('user.newPassword')" prop="new_password">
              <el-input v-model="formData.new_password" controls-position="right" :placeholder="$t('message.inputMsg',{msg:$t('user.newPassword')})"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="modal-footer">
        <el-button @click="handleCloseDialog">{{$t('options.cancel')}}</el-button>
        <el-button type="primary" @click="handleUpdatePassword">{{$t('options.save')}}</el-button>
      </div>
    </template>
  </vxe-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { setUserPasswordService } from '../../services/user'
import { Message } from 'element-ui'
import { closeLoading } from '@/utils/loading'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
import Cookies from 'js-cookie'
import md5 from 'md5'
// import ErrorLog from '@/components/ErrorLog'
// import Screenfull from '@/components/Screenfull'
// import SizeSelect from '@/components/SizeSelect'
// import Search from '@/components/HeaderSearch'

export default {
  components: {
    Breadcrumb,
    Hamburger,
    // ErrorLog,
    // Screenfull,
    // SizeSelect,
    // Search
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device',
      'name'
    ])
  },
  data(){
    return {
      formData:{
        old_password:'',
        new_password:''
      },
      rules: {
        old_password:[
          { required: true, message: this.$t('message.inputMsg',{msg:this.$t('user.oldPassword')}), trigger: 'blur' },
        ],
        new_password:[
          { required: true, message: this.$t('message.inputMsg',{msg:this.$t('user.newPassword')}), trigger: 'blur' },
        ],
      },
      updatePasswordVisible:false,
    }
  },

  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    /**
     * @desc change Language
     */
    changeLang(lang) {
      console.log(lang)
      
      this.$i18n.locale = lang
      Cookies.set('lang',lang)
      this.$moment.locale(lang)
    },

    /**
     * 打开修改密码modal
     */
    handleOpenModal(){
        this.updatePasswordVisible = true
    },


    /**
     * 修改密码btn回调
     */
    handleUpdatePassword() {
      this.$refs['updatePasswordForm'].validate((valid) => {
        if(valid) {
          // this.openLoading('.vxe-modal--box')
          let _params = Object.assign({},this.formData) 
          _params['old_password'] = md5(_params['old_password'])  
          _params['new_password'] = md5(_params['new_password'])  
          this.updatePassword(_params)
        }else{
          return false
        }
      })
    },

    /**
     * 修改密码
     */
    updatePassword(params){

      return new Promise((resolve, reject) => {
        setUserPasswordService(params).then(res => {
          if(res.status === 200 && res.data.status === 'OK'){
            Message.success({message:this.$t('message.updateSomeSuccess',{msg:this.$t('user.updatePassword')})})
            this.handleCloseDialog()
          }else{
            this.closeLoading()
            Message.error({message:this.$t('message.updateSomeFail',{msg:this.$t('user.updatePassword')})})
          }
        }).catch(error => {
          this.closeLoading()
          Message.error({message:error.message || this.$t('message.optionFail')})
        })
      })  
    },

    /**
     * 关闭modal
     */
    handleCloseDialog(){
      this.updatePasswordVisible = false
      this.formData = {
        old_password:'',
        new_password:''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #041a2f;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
    .el-breadcrumb__item{

    .el-breadcrumb__inner a{
      color: hsla(0,0%,100%,.65);
    }
    .el-breadcrumb__item a{
      color: #fff;
    }
    }
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #fff;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
      .user-name{
        font-size: 14px;
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  
  }
}
</style>
