<template>
  <div class="icon-select-div" ref="select_div">
    <!-- <div v-if="iconValue"> -->
      <svg-icon v-if="value" :icon-class="value" solt="prefix" style="float: left" />
      <span v-else solt="prefix"></span>
      <!-- <span>{{ iconValue }}</span> -->
    <!-- </div> -->
    <el-select ref="icon_select" solt="reference" v-model="value" class="icon-select-input" clearable
      :placeholder="placeholder"
      @change="handleChange">
        <el-option v-for="(item) in iconList" :key="item.value" :label="item.label" :value="item.value">
          <svg-icon :icon-class="item.value && iconTable[item.value] ? iconTable[item.value] : ''" style="float: left;height:100%;" />
          <span style="marginLeft:10px">{{ item.label }}</span>
        </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name:"IconSelect",
  props: {
    iconList:{
      type: Array,
      default:'',
    },
    placeholder:{
      type:String,
      default:""
    },
    iconValue:{
      type:String,
      default:""
    },
    keyValue:{
      type:String,
      default:""
    },
    keyItem:{
      type:Object,
      default:''
    },
    iconTable:{
      type:Object,
      default:'',
    }
  },
  data() {
    return {
      value:"",
    }
  },
  created(){
    this.value = this.iconValue
    // this.handleChange(this.iconValue)
  },
  mounted(){
    this.handleChange(this.iconValue)
  },
  methods:{
    handleChange(val){
      if( this.$refs['icon_select']){
        let _dom = this.$refs['icon_select'].$el
        let _inputDom = _dom.children[0].children[0]
        if(val && val !== 'icon'){
          _inputDom.setAttribute('style','padding-left: 36px')
        }else{
          _inputDom.setAttribute('style','padding-left: 15px')
        }
        this.value = this.iconTable[val]
        this.keyValue ? this.$emit('iconChange', val, this.keyValue) :
        this.$emit('iconChange',val, this.keyItem)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.icon-select-div{
  .svg-icon{
    position: absolute;
    z-index: 11;
    left: 20px;
    height: 28px;
  }
}
</style>
