import request from '@/utils/request'
import APICONFIG from '@/utils/api'
import { stringify } from 'qs';

const { login, userInfo, menuTree, menus, user, currentPassword } = APICONFIG

export function loginService(data) {
  return request({
    url: login,
    method: 'post',
    data
  })
}

/**
 * @desc 获取当前用户信息
 */
export function getUserInfoService() {
  return request({
    url: userInfo,
    method: 'get',
  })
}

/**
 * @desc 获取当前用户菜单树
 */
export function getMenuTreeService() {
  return request({
    url:menuTree,
    method:'get',
  })
}

/**
 * 创建用户菜单
 * @param {Object} params
 */
export function setUserMenuService(params) {
  return request({
    url:menus,
    method:"post",
    data:params,
  })
}

//User

/**
 * 获取用户数据数据
 * @param {Object} params
 */
export function getUserService(params) {
  return request({
    url:`${user}?${stringify(params)}`,
    method:'get',
  })
}

/**
 * 添加用户数据
 * @param {Object} parmas
 */
export function addUserService(parmas) {
  return request({
    url:user,
    method:"post",
    data:parmas,
  })
}

/**
 * 更新用户数据
 * @param {Object} parmas
 */
export function setUserService(parmas) {
  return request({
    url:`${user}`+'/'+`${parmas.id}`,
    method:"put",
    data:parmas,
  })
}

/**
 * 查询指定用户数据数据
 * @param {String} id
 */
export function getUserByIdService(id) {
  return request({
    url:`${user}`+'/'+`${id}`,
    method:"get",
  })
}

/**
 * 删除用户数据
 * @param {String} id
 */
export function deleteUserService(id) {
  return request({
    url:`${user}`+'/'+`${id}`,
    method:"delete",
  })
}

/**
 * 更新个人密码
 */
export function setUserPasswordService(parmas) {
  return request({
    url:currentPassword,
    method:"put",
    data:parmas,
  })
} 