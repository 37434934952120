
import Layout from '@/layout'

const businessRouter = {
  path: '/',
  component: Layout,
  code:'BusinessMangment',
  name: 'BusinessMangment',
  meta: {
    title: 'BusinessMangment',
    icon: 'bussiness-managment'
  },
  children: [
    {
      path: '/business/bms',
      component: () => import('@/views/bms/list/index'),
      name: 'BMS',
      meta: { title: 'BMS' }
    },
    {
      path: '/bms/list/bmsDetails',
      redirect: '/business/bms',
      // component: '',
      component: () => import('@/views/bms/index'),
      name: 'BMS',
      meta: { title: 'BMS' },
      children: [
        {
          path: '/',
          component: () => import('@/views/bms/bmsDetails/index'),
          name: 'BmsDetails',
          meta: { title: 'BmsDetails' },
        },
        // {
        //   path: '/ticket/list/ticketDetails',
        //   component: () => import('@/views/ticket/ticketDetails/index'),
        //   name: 'TicketDetails',
        //   meta: { title: 'TicketDetails' }
        // },
      ] 
    }, 
    {
      path: '/business/ebs',
      component: () => import('@/views/disk/index'),
      name: 'EBS',
      meta: { title: 'EBS' }
    },
    {
      path: '/business/eip',
      component: () => import('@/views/IP/index'),
      name: 'EIP',
      meta: { title: 'EIP' }
    },
    {
      path: '/business/vpc',
      component: () => import('@/views/vpc/index'),
      name: 'VPC',
      meta: { title: 'VPC' }
    },
    {
      path: '/business/diskImage',
      component: () => import('@/views/diskImage/index'),
      name: 'DiskCustomImage',
      meta: { title: 'DiskCustomImage' }
    },
    {
      path: '/business/diskSnap',
      component: () => import('@/views/diskSnap/index'),
      name: 'DiskSnap',
      meta: { title: 'DiskSnap' }
    },
  ]
}

export default businessRouter