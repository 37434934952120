<template>
  <div :class="{'has-logo':showLogo}">
    <!-- <logo v-if="showLogo" :collapse="isCollapse" /> -->
    <logo :collapse="isCollapse" />
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :collapse="isCollapse"
        :text-color="variables.menuText"
        :unique-opened="false"
        :active-text-color="variables.menuActiveText"
        :collapse-transition="false"
        mode="vertical"
      >
      <!-- <label v-for="menu in menutree" :key="menu.code">
        <el-menu-item v-if="!menu.children">{{ menu.name }}</el-menu-item>
        <el-submenu v-if="menu.children && menu.children[0]" :key="menu.code" :data="menu" :index="menutree.id">
            <span>{{ menu.name}}</span>
        </el-submenu>
      </label> -->
      <sub-menu :menuInfo="menutree"></sub-menu>
        <!-- <sidebar-item v-for="route in menutree" :key="route.code" :item="route" :base-path="route.router" /> -->
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Logo from './Logo'
import SidebarItem from './SidebarItem'
import SubMenu from './SubMenu'
import variables from '@/styles/variables.scss'
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      routes: this.$route,
    }
  },
  components: { SidebarItem, Logo, SubMenu },
  computed: {
    ...mapGetters([
      'permission_routes',
      'sidebar',
      'menutree'
    ]),
    activeMenu() {
      // const route = this.$route
      // const { meta, path } = route
      // if (meta.activeMenu) {
      //   return meta.activeMenu
      // }
      let _activeMenu = Cookies.get('activeMenu') 
      return _activeMenu
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    }
  }
}
</script>
<style lang="scss" scoped>
  .el-scrollbar{
    .scrollbar-wrapper{
      margin-right: 0 !important;
    }
  }
</style>