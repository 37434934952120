<template>
  <div style="padding: 0 15px;" @click="toggleClick">
    <svg-icon v-if="isActive" icon-class="retract" />
    <svg-icon v-else icon-class="open" />
  </div>
</template>

<script>
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick() {
      console.log(this.isActive)
      this.$emit('toggleClick')
    }
  }
}
</script>

<style scoped>
.hamburger {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
}

.hamburger.is-active {
  transform: rotate(180deg);
}
</style>
