// import { login, logout, getInfo } from '@/api/user'
import { loginService, getUserInfoService, getMenuTreeService } from '@/services/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { asyncRoutes, constantRoutes } from '@/router'
import { arrConversion, deepClone } from '@/utils'
import { Message } from 'element-ui'
import Cookies from 'js-cookie'

import Layout from '@/layout'
import router from '@/router'


export function filterAsyncRoutes(asyncRoutes, menuTree) {
  let res
	let _codeList = []
  let _asyncRoutes = deepClone(asyncRoutes)
  let _menuArr = arrConversion(menuTree,[])
	_menuArr.filter(obj=> _codeList.push(obj.code));
	// res = _asyncRoutes.filter(obj=>_codeList.indexOf(obj.code) !==-1)
  // let _404Route = { path: '*', redirect: '/404', hidden: true }
  res = filterUserRoutes(_asyncRoutes,_codeList)
  // _asyncRoutes.forEach(route => {
  //   const tmp = { ...route }
  //   if (_codeList.indexOf(tmp.code)) {
  //     if (tmp.children) {
  //       tmp.children = filterAsyncRoutes(tmp.children)
  //     }
  //     res.push(tmp)
  //   }
  // })
  // res.push(_404Route)
  return res
}

export function filterUserRoutes(userRoutes,codeList){
  const res = []
  userRoutes.forEach(route => {
    const tmp = { ...route }
    // console.log(tmp)
    if (codeList.indexOf(tmp.code)) {
      if (tmp.children) {
        tmp.children = filterUserRoutes(tmp.children,codeList)
      }
      res.push(tmp)
    }
  })
  
  return res
}

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  menutree:[],
  routes: [],
  userRoutes: [],
  addRoutes: [],
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_MENUTREE: (state, menutree) => {
    state.menutree = menutree
  },
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
    
    router.addRoutes(constantRoutes.concat(routes))
  },
  SET_ACTIVEMENU: (state, menu) => {
    Cookies.set('activeMenu', menu)
  },
  
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    // const { user_name, password } = userInfo
    // console.log(userInfo)
    return new Promise((resolve, reject) => {
      loginService(userInfo).then(response => {
        const { data } = response
        if(response.status === 200){
          // console.log(window.location.origin)
          commit('SET_TOKEN', data.access_token)
          commit('SET_ACTIVEMENU','/dashboard')
          // commit('SET_ROLES','admin')
          setToken(data)
          resolve()
        }else{

          Message.error({message:'登陆失败'})
        }
      }).catch(error => {
        Message.error({message:'登陆失败'})
        reject(error)
      })
    })
  },

  // get user info
  getMenuTree({ commit, state, dispatch }) {
    // console.log(constantRoutes)
    // console.log(asyncRoutes)
    
    // dispatch('generateRoutes')
    return new Promise((resolve, reject) => {
      getMenuTreeService({ }).then(response => {
        const { data } = response
        
        // console.log(data.list)
        let _menuTree = []
        if(data.list && data.list[0]){
          data.list.forEach(item =>{
            if(item.children && item.children[0]){
              let _count = 0
              item.children.map(subItem => {
                if(subItem.show_status === 1){
                  _count += 1
                }
                if(subItem.children && subItem.children[0]){
                  let _subCount = 0
                  subItem.children.map(subSubItem => {
                    if(subSubItem.show_status === 1){
                      _subCount += 1
                    }
                  })
                  if(_subCount > 0){
                    subItem.showChildren = true
                  }
                }
              })
              if(_count > 0){
                item.showChildren = true
              }
            }else{
              item.showChildren = true
            }
          })
        }
        // state.userRoutes = routes.filter(router => {
        //   return data.list.some(menu => menu.name === router.code)
        // })
        // dispatch('generateRoutes',data.list)
        commit('SET_MENUTREE', data.list)
        // setToken(data.token)
        // console.log(data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  generateRoutes({ commit }, menuTree) {
    // console.log("coming")
    return new Promise(resolve => {
      let accessedRoutes
      // if (roles.includes('admin')) {
      //   accessedRoutes = asyncRoutes || []
      // } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, menuTree)
      // }
      // console.log(accessedRoutes)
      // router.addRoutes(accessedRoutes)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },

  // get user info
  getUserInfo({ commit, state }) {
    // console.log("coming")
    return new Promise((resolve, reject) => {
      getUserInfoService({ }).then(response => {
        const { data } = response
        // commit('SET_TOKEN', data.token)
        // setToken(data.token)
        
        // commit('SET_ROLES', ['admin'])
        commit('SET_NAME', data.user_name)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
    // return new Promise((resolve, reject) => {
    //   getInfo(state.token).then(response => {
    //     const { data } = response

    //     if (!data) {
    //       reject('Verification failed, please Login again.')
    //     }

    //     const { roles, name, avatar, introduction } = data

    //     // roles must be a non-empty array
    //     if (!roles || roles.length <= 0) {
    //       reject('getInfo: roles must be a non-null array!')
    //     }

    //     commit('SET_ROLES', roles)
    //     commit('SET_NAME', name)
    //     commit('SET_AVATAR', avatar)
    //     commit('SET_INTRODUCTION', introduction)
    //     resolve(data)
    //   }).catch(error => {
    //     reject(error)
    //   })
    // })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    // return new Promise()
    // return new Promise((resolve, reject) => {
    //   logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          removeToken()
        // resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        router.push('/login')
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    // })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },
  

  // dynamically modify permissions
  // async changeRoles({ commit, dispatch }, role) {
  //   const token = role + '-token'

  //   commit('SET_TOKEN', token)
  //   setToken(token)

  //   const { roles } = await dispatch('getInfo')

  //   resetRouter()

  //   // generate accessible routes map based on roles
  //   const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
  //   // dynamically add accessible routes
  //   router.addRoutes(accessRoutes)

  //   // reset visited views and cached views
  //   dispatch('tagsView/delAllViews', null, { root: true })
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
