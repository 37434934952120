import Vue from 'vue'
import i18n from '../common/lang/index'
import {
  VXETable,
  Edit,
  Table,
  Column,
  Header,
  Footer,
  Icon,
  Grid,
  Pager,
  Filter,
  Button,
  Select,
  Toolbar,
  Checkbox,
  Input,
  Form,
  Modal,
  Tooltip,
  Validator,
  Keyboard,
} from 'vxe-table'
// Vue.use(VXETable, {
//   // 可选，对参数中的列头、校验提示..等进行自动翻译（只对支持国际化的有效）
//   translate: key => i18n.t(key),
//   // 对组件内置的提示语进行国际化翻译
//   i18n: key => i18n.t(key)
// })
VXETable.setup({
      // i18n: key => XEUtils.get(key),
    // i18n: key => i18n.t(key),
    i18n: (key, value) => i18n.t(key, value),
    // i18n: (key, value) => XEUtils.toFormatString(XEUtils.get(Cookies.get('lang'), key), value),
    translate: key => i18n.t(key),
    border:false,
    size:'mini',
    toolbar: { custom: { isFooter: false, immediate: true } },
    
    tablePage: {
      total: 0,
      currentPage: 1,
      pageSize: 10,
      align: 'right',
      pageSizes: [10, 20, 50, 100, 200, 500],
      layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
      // perfect: true
    },
})

// VXETable.renderer.add('MyFilter', {
//   // 筛选模板
//   renderFilter (h, filterRender, params, context) {
//     let { column } = params
//     console.log(column)
//     return column.filters.map(option => {
//       return <input type="text" v-modal={ option.data } onInput={ evnt => { context.changeOption(evnt, !!option.data, option) } }/>
//     })
//     // return column.filters.map(option => {
//     //   option.value = option.data
//     //   return <input class="filter-input" type="text" v-model={ option.data } onInput={ evnt => { context.changeOption(evnt, !!option.data, option) } }/>
//     // })
//   },
// })

// 先安装依赖模块
Vue.use(Edit)
Vue.use(Icon)
Vue.use(Column)
Vue.use(Header)
Vue.use(Footer)
Vue.use(Grid)
Vue.use(Pager)
Vue.use(Select)
Vue.use(Button)
Vue.use(Toolbar)
Vue.use(Checkbox)
Vue.use(Input)
Vue.use(Form)
Vue.use(Filter)
Vue.use(Modal)
Vue.use(Tooltip)
Vue.use(Validator)
Vue.use(Keyboard)

// 再安装核心库
Vue.use(Table)
