
import Layout from '@/layout'

const sysRouter = {
  path: '/',
  component: Layout,
  // redirect: '/customer/list',
  code:'System',
  name: 'System',
  meta: {
    title: 'SystemMangemnt',
    icon: 'System'
  },
  children: [
    {
      path: '/system/user',
      component: () => import('@/views/sysUser/list/index'),
      name: 'SysUser',
      meta: { title: 'SysUser' }, 
    },
    {
      path: '/system/user/details',
      redirect: '/system/user',
      // component: '',
      component: () => import('@/views/sysUser/details/index'),
      name: 'SysUserDetails',
      meta: { title: 'SysUserDetails' },
      children: [
        {
          path: '/',
          component: () => import('@/views/customerList/details/index'),
          name: 'SysUserDetails',
          meta: { title: 'SysUserDetails' },
        },
      ] 
    }, 
    {
      path: '/system/role',
      component: () => import('@/views/sysRole/index'),
      name: 'SysRole',
      meta: { title: 'SysRole' }
    },
    {
      path: '/system/menu',
      component: () => import('@/views/sysMenu/index'),
      name: 'Menu',
      meta: { title: 'SysMenu' }
    },
  ]
}

export default sysRouter