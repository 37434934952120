
import Layout from '@/layout'

const couponRouter = {
  path: '/coupon',
  component: Layout,
  redirect: '/coupon/list',
  code:'CouponMangemnt',
  name: 'CouponMangemnt',
  meta: {
    title: 'CouponMangemnt',
    icon: 'coupon'
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/coupons/index'),
      name: 'CouponList',
      meta: { title: 'CouponList' }
    },
    {
      path: 'log',
      component: () => import('@/views/couponLogs/index'),
      name: 'CouponLog',
      meta: { title: 'CouponLog' }
    },
  ]
}

export default couponRouter