import enLang from 'element-ui/lib/locale/lang/en'
import vexEnUS from 'vxe-table/lib/locale/lang/en-US';

const en = {
  menu:{
    Dashboard:"Dashboard",
    CustomerMangment:"CustomerMangment",
    CustomerList:"CustomerList",
    GradeCfg:"GradeCfg",
    AuditList:"AuditList",
    BusinessMangment:"BusinessMangment",
    BMS:"BMS",
    EBS:"EBS",
    SBG:"SBG",
    EIP:"EIP",
    VPC:"VPC",
    OrderMangment:"OrderMangment",
    OrderList:"OrderList",
    FinaceMangment:"FinaceMangment",
    FundFlows:"FundFlows",
    ConsumeRecord:"ConsumeRecord",
    ChargeRecord:"ChargeRecord",
    TicketMangment:"TicketMangment",
    TicketList:"TicketList",
    TicketType:"TicketType",
    NoticeMangment:"NoticeMangment",
    InnerMsg:"InnerMsg",
    MsgHistory:"MsgHistory",
    ProductMangemnt:"ProductMangemnt",
    DataCenter:"DataCenter",
    BmsCombo:"BmsCombo",
    BmsProduct:"BmsProduct",
    EbsProduct:"EbsProduct",
    LinesProduct:"LinesProduct",
    EipProduct:"EipProduct",
    ImagesProduct:"ImagesProduct",
    TimeRatio:"TimeRatio",
    GenericProduct:"GenericProduct",
    ConfigMangemnt:"ConfigMangemnt",
    SmsCofig:"SmsCofig",
    MailCofig:"MailCofig",
    PayConfig:"PayConfig",
    SiteConfig:"SiteConfig",
    SystemMangemnt:"SystemMangemnt",
    SysMenu:"SysMenu",
    SysRole:"SysRole",
    SysUser:"SysUser",
  },
  options:{
    edit:"Edit",
    add:"Add",
    delete:"Delete",
    cancel:"Cancel",
    addSome:"Add {des}",
    editSome:"Edit {des}",
    settingPrice:"Setting Price",
  },
  common:{
    status:"Status",
    createTime:"Create Time",
  },
  login:{
    login:"Login",
    logout:"Logout",
    password:"Password",
  },
  system:{
    menu:"Menu",
    menuName:"Name",
    code:"Code",
    sequence:"Sequence",
    icon:"Icon",
    path:"Path",
  },
  message:{
    searchByKey:"Please enter {key} search",
  },
  ...vexEnUS,
  ...enLang
}

export default en