
import Layout from '@/layout'

const orderRouter = {
  path: '/order',
  component: Layout,
  redirect: '/order/list',
  code:'OrderMangment',
  name: 'OrderMangment',
  meta: {
    title: 'OrderMangment',
    icon: 'order'
  },
  children: [
    {
      path: 'list',
      component: () => import('@/views/order/index'),
      name: 'OrderList',
      meta: { title: 'OrderList' }
    },
  ]
}

export default orderRouter