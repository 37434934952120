const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  unitTable: state => state.app.unitTable,
  autoRenew: state => state.app.autoRenewList,
  pageing: state => state.app.pageing,
  statusList: state => state.app.statusList,
  currencySymbolTable: state => state.app.currencySymbolTable,
  basePriceUnit: state => state.app.basePriceUnit,
  basePriceCurrency: state => state.app.basePriceCurrency,
  originAddress: state => state.app.originAddress,

  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  errorLogs: state => state.errorLog.logs,
  menutree: state => state.user.menutree,
  routerTree: state => state.user.routerTree,
  routes: state => state.user.routes,

  permission_routes: state => state.permission.routes,
  lineTypeTable: state => state.product.lineTypeTable,
  nodeTypeList: state => state.product.nodeTypeList,
  nodeTypeTable: state => state.product.nodeTypeTable,
  productCodeList: state => state.product.productCodeList,
  currency: state => state.finance.currency,
  tradeChannel: state => state.finance.tradeChannel,
  tradeType: state => state.finance.tradeType,
  tradeDirction: state => state.finance.tradeDirction,
  chargeType: state => state.finance.chargeType,
  chargeTypeTable: state => state.finance.chargeTypeTable,

  networkTypeList: state => state.business.networkTypeList,
  networkTypeTable: state => state.business.networkTypeTable,
  bmsStateTable: state => state.business.bmsStateTable,
  runStateTable: state => state.business.runStateTable,
  imageType: state => state.business.imageType,
  stateDetailTable: state => state.business.stateDetailTable,
  ipStateTable: state => state.business.ipStateTable,
  ebsStateTable: state => state.business.ebsStateTable,
  diskImageStateTable: state => state.business.diskImageStateTable,
  diskSnapStateTable: state => state.business.diskSnapStateTable,
  vpcStateTable: state => state.business.vpcStateTable,

  codeTypeTable: state => state.settings.codeTypeTable,
  codeType:state => state.settings.codeType
}
export default getters
