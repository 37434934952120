
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
    ele.className = ele.className.replace(reg, ' ')
  }
}

/**
 * 将菜单转化为一维数组
 * @param {Array} arr
 * @param {Array} newArr
 */
export function arrConversion(arr, newArr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].children) {
      arrConversion(arr[i].children, newArr)
    } else {
      newArr.push(arr[i])
    }
  }
  return newArr
}

/**
 * deepClone
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

/**
 * 处理树形菜单数据
 * @param {Array}
 */
export function addLabelToArr(arr) {
  for (let i =0; i < arr.length; i++){
    if(arr[i].children){
      addLabelToArr(arr[i].children)
    }else(
      arr[i].label = arr[i].name
    )
  }
}

/**
 * @desc 首字母大写
 * @param {String} str
 */
export function toLowerCaseFirst(str) {
  str = str.replace(str[0],str[0].toUpperCase())
  return str
}

/**
 * 随机字符串
 */
export function randomString(e) {  
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678"
  let a = t.length
  let n = ""
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}

/**
 * 设置查询参数
 */
export function getQueryParam(pagination, searchKey, startTime, endTime) {
  let _params = Object.assign({},pagination)
  _params['total'] ? delete _params['total'] : ''
  _params['queryValue'] = searchKey
  startTime ? _params['startTime'] = parseInt(startTime.toString().substring(0,startTime.toString().length-3)) :''
  endTime ? _params['endTime'] = parseInt(endTime.toString().substring(0,endTime.toString().length-3)) :''
  console.log(_params)
  return _params
}

/**
 * @desc 数组转化表格
 */
export function formatterArrToTable (arr){
  let _table = {}
  arr && arr[0] ? arr.map(item => {
    _table[item.value] = item.label
  }) : ''
  return _table
}

export const LoadingConfig = ({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgb(0 0 0 / 35%)',
})

export function getNowYear (){
  let _date = new Date()
  let _year = _date.getFullYear()
  return _year
}

export function getNowMonth (){
  let _date = new Date()
  let _month = _date.getMonth() + 1
  return _month
}


