import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import productRouter from './modules/product'
import financeRouter from './modules/finance'
import businessRouter from './modules/business'
import orderRouter from './modules/order'
import customerRouter from './modules/customer'
import settingRouter from './modules/setting'
import messageRouter from './modules/message'
import innerMsgRouter from './modules/innerMsg'
import couponRouter from './modules/coupon'
import sysRouter from './modules/system'



/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  // {
  //   path: '/redirect',
  //   component: Layout,
  //   hidden: true,
  //   children: [
  //     {
  //       path: '/redirect/:path(.*)',
  //       component: () => import('@/views/redirect/index')
  //     }
  //   ]
  // },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/serialPort',
    component: () => import('@/views/bms/list/serialPort/serialPort'),
    hidden: true
  },
  {
    path: '/mixingSerialPort',
    component: () => import('@/views/bms/list/serialPort/Ikvm'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    // redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: 'Dashboard', icon: 'dashboard', affix: true }
      }, 
      {
        path: '/404',
        component: () => import('@/views/errorPage/404'),
        hidden: true
      },
      {
        path: '/mixingSerialPort',
        component: () => import('@/views/bms/list/serialPort/Ikvm'),
        hidden: true
      }, 
      {
        path: '/401',
        component: () => import('@/views/errorPage/401'),
        hidden: true
      }, 
      {
        path: '/',
        component: () => import('@/views/errorPage/401'),
        hidden: true
      }, 
    ]
  },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [

  {
    path: '/dashboard',
    component: Layout,
    code:'Dashboard',
    redirect: '/dashboard',
    children: [
      {
        path: '',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: 'Dashboard', icon: 'dashboard', affix: true }
      },
    ]
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   code:'CustomerList',
  //   children: [
  //     {
  //       path: '/customer/list',
  //       component: () => import('@/views/customerList/index'),
  //       name: 'CustomerList',
  //       meta: { title: 'CustomerList', icon: 'customer' }
  //     }
  //   ]
  // },
    productRouter,
    financeRouter,
    businessRouter,
    orderRouter,
    customerRouter,
    settingRouter,
    messageRouter,
    innerMsgRouter,
    couponRouter,
    sysRouter,
  // {
  //   path: '/product',
  //   component: Layout,
  //   redirect: '/product/dataCenter',
  //   code:'ProductMangemnt',
  //   name: 'ProductMangemnt',
  //   meta: {
  //     title: 'ProductMangemnt',
  //     icon: 'product'
  //   },
  //   children: [
  //     {
  //       path: 'dataCenter',
  //       component: () => import('@/views/dataCenter/index'),
  //       name: 'DataCenter',
  //       meta: { title: 'DataCenter' }
  //     },
  //   ]
  // },
  // {
  //   path: '/system',
  //   component: Layout,
  //   redirect: '/system/menu',
  //   code:'System',
  //   name: 'System',
  //   meta: {
  //     title: 'SystemMangemnt',
  //     icon: 'System'
  //   },
  //   children: [
  //     {
  //       path: 'menu',
  //       component: () => import('@/views/sysMenu/index'),
  //       name: 'Menu',
  //       meta: { title: 'SysMenu' }
  //     },
  //     {
  //       path: 'role',
  //       component: () => import('@/views/sysRole/index'),
  //       name: 'SysRole',
  //       meta: { title: 'SysRole' }
  //     },
  //     {
  //       path: 'user',
  //       component: () => import('@/views/sysUser/index'),
  //       name: 'SysUser',
  //       meta: { title: 'SysUser' }
  //     },
  //     {
  //       path: 'user/details',
  //       // component: '',
  //       component: () => import('@/views/sysUser/index'),
  //       name: 'CustomerList',
  //       meta: { title: 'CustomerList' },
  //       children: [
  //         {
  //           path: '/',
  //           component: () => import('@/views/sysUser/details/index'),
  //           name: 'CustomerDetails',
  //           meta: { title: 'CustomerDetails' },
  //         },
  //       ] 
  //     }, 
  //   ]
  // },
  // {
  //   path: '/401',
  //   component: Layout,
  //   code:'401',
  //   children: [
  //     {
  //       path: '/',
  //       component: () => import('@/views/errorPage/401'),
  //       name: 'SysMenu',
  //       meta: { title: 'Menu', icon: 'edit' }
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   code:'SysRole',
  //   children: [
  //     {
  //       path: '/system/role',
  //       component: () => import('@/views/sysRole/index'),
  //       name: 'SysRole',
  //       meta: { title: 'Menu', icon: 'edit' }
  //     }
  //   ]
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   code:'SysUser',
  //   children: [
  //     {
  //       path: '/system/user',
  //       component: () => import('@/views/sysUser/index'),
  //       name: 'SysUser',
  //       meta: { title: 'Menu', icon: 'edit' }
  //     }
  //   ]
  // },

  // 404 page must be placed at the end !!!
  { path: '*', redirect: '/404', hidden: true }
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
