import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ElementLocale from 'element-ui/lib/locale'
import ENUS from './en-us'
import ZHCN from './zh-cn'
import Cookies from 'js-cookie'

Vue.use(VueI18n);

const messages = {
  'en-us': ENUS,
  'zh-cn': ZHCN
}   
const dateTimeFormats = {
  'en-us': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }, 
    weekday: {
      weekday: 'long',
    }
  },
  'zh-cn': {
    short: {
      year: 'numeric', month: 'numeric', day: 'numeric'
    },
    long: {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: '2-digit', minute: 'numeric', hour12: false
    }, 
    weekday: {
      weekday: 'long',
    }
  }
}


const i18n = new VueI18n({
  locale: Cookies.get('lang') || 'zh-cn',
  messages,
  dateTimeFormats
})
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n

// export default new VueI18n({
//   locale:'en-us',
//   messages,
//   dateTimeFormats,
// })