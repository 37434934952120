import { Loading } from 'element-ui'

let loading 
export const openLoading = function(_target){
  loading = Loading.service({  
    lock: true,
    fullscreen:false,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgb(0 0 0 / 35%)',
    target:_target,
  })
}

export const closeLoading = function(){
  loading.close()
}