// import variables from '@/styles/element-variables.scss'
import defaultSettings from '@/settings'

const { showSettings, tagsView, fixedHeader, sidebarLogo } = defaultSettings

const state = {
  // theme: variables.theme,
  showSettings: showSettings,
  tagsView: false,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  codeType:[
    // {
    //   value:"SMSVerifyKeyPrefix",
    //   label:'SMSVerifyKeyPrefix'
    // },
    {
      value:"CommonVerifyCode",
      label:'CommonVerifyCode'
    },
    {
      value:"UserRegister",
      label:'UserRegister'
    },
    {
      value:"UserLogin",
      label:'UserLogin'
    },
    {
      value:"UserRetrievePassword",
      label:'UserRetrievePassword'
    },
    {
      value:"UserUpdatePhone",
      label:'UserUpdatePhone'
    },
    {
      value:"UserUpdateEmail",
      label:'UserUpdateEmail'
    },
    // {
    //   value:"UserBindPhone",
    //   label:'UserBindPhone'
    // },
    {
      value:"HostReinstallOS",
      label:'HostReinstallOS'
    },
    {
      value:"HostPasswordRest",
      label:'HostPasswordRest'
    },
    {
      value:"ChargeSuccess",
      label:'ChargeSuccess'
    },
    {
      value:"InstanceWillExpired",
      label:'InstanceWillExpired'
    },
    {
      value:"InsufficientBalance",
      label:'InsufficientBalance'
    },
    {
      value:"ServiceSuspended",
      label:'ServiceSuspended'
    },
    {
      value:"RenewSuccess",
      label:'RenewSuccess'
    },
  ],
  codeTypeTable:{
    // 'SMSVerifyKeyPrefix':'SMSVerifyKeyPrefix',
    'CommonVerifyCode':'CommonVerifyCode',
    'UserRegister':'UserRegister',
    'UserLogin':'UserLogin',
    'UserRetrievePassword':'UserRetrievePassword',
    'UserUpdatePhone':'UserUpdatePhone',
    'UserUpdateEmail':'UserUpdateEmail',
    // 'UserBindPhone':'UserBindPhone',
    'HostReinstallOS':'HostReinstallOS',
    'HostPasswordRest':'HostPasswordRest',
    'ChargeSuccess':'ChargeSuccess',
    'InstanceWillExpired':'InstanceWillExpired',
    'InsufficientBalance':'InsufficientBalance',
    'ServiceSuspended':'ServiceSuspended',
    'RenewSuccess':'RenewSuccess',
  }
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

