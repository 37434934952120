import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

//UserNo
export function getUserNo() {
  return Cookies.get('UserNo')
}

export function setUserNo(userNo) {
  return Cookies.set('UserNo', userNo)
}

export function removeUserNo() {
  return Cookies.remove('UserNo')
}
