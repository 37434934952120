
import Layout from '@/layout'

const financeRouter = {
  path: '/finance',
  component: Layout,
  redirect: '/finance/fundFlow',
  code:'FinanceMangment',
  name: 'FinanceMangment',
  meta: {
    title: 'FinanceMangment',
    icon: 'finance'
  },
  children: [
    {
      path: 'fundFlow',
      component: () => import('@/views/fundFlow/index'),
      name: 'FundFlows',
      meta: { title: 'FundFlows' }
    },
    {
      path: 'chargeRecord',
      component: () => import('@/views/chargeRecord/index'),
      name: 'ChargeRecord',
      meta: { title: 'ChargeRecord' }
    },
    {
      path: 'consumeRecord',
      component: () => import('@/views/consumeRecord/index'),
      name: 'ConsumeRecord',
      meta: { title: 'ConsumeRecord' }
    },
  ]
}

export default financeRouter