
import Layout from '@/layout'

const customerRouter = {
  path: '/',
  component: Layout,
  // redirect: '/customer/list',
  code:'CustomerMangment',
  name: 'CustomerMangment',
  meta: {
    title: 'CustomerMangment',
    icon: 'customer-managment'
  },
  children: [
    {
      path: '/customer/list',
      component: () => import('@/views/customerList/list/index'),
      name: 'CustomerList',
      meta: { title: 'CustomerList' }, 
      // children: [
      //   // {
      //   //   path: '/',
      //   //   component: () => import('@/views/customerList/list/index'),
      //   //   name: 'CustomerList',
      //   //   meta: { title: 'CustomerList' },
      //   // },
      //   {
      //     path: '/customer/details',
      //     component: () => import('@/views/customerList/details/index'),
      //     name: 'CustomerDetails',
      //     meta: { title: 'CustomerDetails' }
      //   },
      // ] 
    },
    {
      path: '/customer/list/details',
      redirect: '/customer/list',
      // component: '',
      component: () => import('@/views/customerList/index'),
      name: 'CustomerList',
      meta: { title: 'CustomerList' },
      children: [
        {
          path: '/',
          component: () => import('@/views/customerList/details/index'),
          name: 'CustomerDetails',
          meta: { title: 'CustomerDetails' },
        },
      ] 
    }, 
    {
      path: '/customer/list/details/bmsDetails',
      redirect: '/customer/list/details',
      // component: '',
      component: () => import('@/views/customerList/index'),
      name: 'CustomerDetails',
      meta: { title: 'CustomerDetails' },
      children: [
        {
          path: '/',
          component: () => import('@/views/customerList/details/bmsDetails'),
          name: 'BmsDetails',
          meta: { title: 'BmsDetails' },
        },
      ] 
    }, 
    {
      path: '/customer/grade',
      component: () => import('@/views/customerGrade/index'),
      name: 'GradeCfg',
      meta: { title: 'GradeCfg' }
    },
    {
      path: '/customer/audit',
      // component: '',
      component: () => import('@/views/customerAudit/index'),
      name: 'Audit',
      meta: { title: 'Audit' },
      children: [
        {
          path: '/',
          component: () => import('@/views/customerAudit/list/index'),
          name: 'AuditList',
          meta: { title: 'AuditList' },
        },
        {
          path: '/customer/audit/auditType',
          component: () => import('@/views/customerAudit/audit/auditType'),
          name: 'AuditType',
          meta: { title: 'AuditType' }
        },
        {
          path: '/customer/audit/personal',
          component: () => import('@/views/customerAudit/audit/personal'),
          name: 'PersonalAudit',
          meta: { title: 'PersonalAudit' }
        },
        {
          path: '/customer/audit/business',
          component: () => import('@/views/customerAudit/audit/business'),
          name: 'BusinessAudit',
          meta: { title: 'BusinessAudit' }
        },
        {
          path: '/customer/audit/details',
          component: () => import('@/views/customerAudit/audit/auditDetalis'),
          name: 'AuditDetails',
          meta: { title: 'AuditDetails' }
        },
      ] 
    }, 
    // {
    //   path: '/customer/audit/auditType',
    //   component: () => import('@/views/customerAudit/audit/auditType'),
    //   name: 'AuditType',
    //   meta: { title: 'AuditType' }
    // },
    //  {
    //   path: 'audit/auditType',
    //   component: () => import('@/views/customerAudit/audit/auditType'),
    //   name: 'AuditType',
    //   meta: { title: 'AuditType' }
    // },
  ]
}

export default customerRouter