const state = {
  currency: [
    {
      value:'CNY',
      label:'CNY',
    },
    {
      value:'USD',
      label:'USD',
    },
    {
      value:'HKD',
      label:'HKD',
    },
  ],
  tradeChannel:[
    {
      value:'ALIPAY',
      label:'ALIPAY',
    },{
      value:'WECHAT',
      label:'WECHAT',
    },{
      value:'ACCT_CASH',
      label:'ACCT_CASH',
    },
    // {
    //   value:'BANK',
    //   label:'BANK',
    // },{
    //   value:'CREDIT',
    //   label:'CREDIT',
    // },
    {
      value:'OFFLINE_REMIT',
      label:'OFFLINE_REMIT',
    },
  ],
  tradeType:[
    {
      value:1,
      label:'recharge',
    },{
      value:2,
      label:'refund'
    },{
      value:3,
      label:'consumption'
    },{
      value:4,
      label:'withdrawal'
    },
  ],
  tradeDirction:[
    {
      value:1,
      label:'income',
    },{
      value:2,
      label:'expenditure',
    },
  ],
  chargeType:[
    {
      value:1,
      label:'prepaid',
    },
    {
      value:2,
      label:'payment'
    }
  ],
  chargeTypeTable:{
    1:'prepaid',
    2:'payment',
  }
}

export default {
  namespaced: true,
  state,
}

