
import Layout from '@/layout'

const messageRouter = {
  path: '/',
  redirect: '/ticket/list',
  component: Layout,
  code:'TicketMangment',
  name: 'TicketMangment',
  meta: {
    title: 'TicketMangment',
    icon: 'ticket'
  },
  children: [
    {
      path: '/ticket/list',
      component: () => import('@/views/ticket/list/index'),
      name: 'TicketList',
      meta: { title: 'TicketList' }
    },
    {
      path: '/ticket/type',
      component: () => import('@/views/ticketType/index'),
      name: 'TicketType',
      meta: { title: 'TicketType' }
    },
    {
      path: '/ticket/list/ticketDetails',
      redirect: '/ticket/list',
      // component: '',
      component: () => import('@/views/ticket/index'),
      name: 'TicketList',
      meta: { title: 'TicketList' },
      children: [
        {
          path: '/',
          component: () => import('@/views/ticket/ticketDetails/index'),
          name: 'TicketDetails',
          meta: { title: 'TicketDetails' },
        },
        // {
        //   path: '/ticket/list/ticketDetails',
        //   component: () => import('@/views/ticket/ticketDetails/index'),
        //   name: 'TicketDetails',
        //   meta: { title: 'TicketDetails' }
        // },
      ] 
    }, 
  ]
}

export default messageRouter