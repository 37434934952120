import Vue from 'vue'
import Element from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import i18n from './common/lang/index'
import IconSelect from '@/components/IconSelect'
import './configs/components_use'
import './plugins/utils'
import './plugins/table'
import './icons' // icon
import './permission' // permission control
// import './styles/element-variables.scss'
import 'element-ui/lib/theme-chalk/index.css'
// import 'normalize.css/normalize.css'
import '@/styles/index.scss'
import 'vxe-table/lib/index.css'
import CopyBoard from '@/components/Copyboard'
import Moment from 'moment'
// import Echarts from 'echarts'

import echarts from 'echarts'

import { openLoading, closeLoading } from '@/utils/loading'


Moment.locale(Cookies.get('lang')) || 'zh-cn'
Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  locale: Cookies.get('lang') || 'zh-cn',//当前语言 
})

Vue.config.productionTip = false
Vue.prototype.openLoading = function(_target) {
  return openLoading(_target)
}
Vue.prototype.closeLoading = function(){
  return closeLoading() 
}  
Vue.prototype.$echarts = echarts
Vue.filter('formatDate', function(value) {
  return Moment(value).format('YYYY-MM-DD HH:mm:ss')
})

Vue.prototype.$moment = Moment

Vue.prototype.$formatDataMoment =  function(value){
  return Moment(value).format('YYYY-MM-DD HH:mm:ss')
}  
// Vue.prototype.$echarts = Echarts
Vue.component('icon-select', IconSelect)
Vue.component('copye-board', CopyBoard)
new Vue({
  // el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
