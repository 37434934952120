import Vue from 'vue';
import {
  Pagination,
  Switch,
  Menu,
  Submenu,
  MenuItem,
  Scrollbar,
  Tooltip,
  Dropdown,
  Icon,
  Tree,
  Breadcrumb,
  BreadcrumbItem,
  Table,
  TableColumn,
  Dialog,
  Row,
  Col,
  Form,
  FormItem,
  Select,
  Option,
  Divider,
  Popconfirm,
  Tag,
  Radio,
  RadioGroup,
  Collapse,
  CollapseItem,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  DatePicker,
  Drawer,
  PageHeader,
  Upload,
  Image,
  Step,
  Steps,
  Tabs,
  TabPane,
} from 'element-ui';

Vue.use(Pagination)
Vue.use(Switch)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Scrollbar)
Vue.use(Tooltip)
Vue.use(Dropdown)
Vue.use(Icon)
Vue.use(Tree)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Dialog)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Select)
Vue.use(Option)
Vue.use(Divider)
Vue.use(Popconfirm)
Vue.use(Tag)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(CheckboxButton)
Vue.use(DatePicker)
Vue.use(Drawer)
Vue.use(PageHeader)
Vue.use(Upload)
Vue.use(Image)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Tabs)
Vue.use(TabPane)
