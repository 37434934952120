<template>
  
    <el-tooltip placement="top">
      <div slot="content">{{$t('options.clickCopy')}} </div>
        <p class="no-margin tag-read" :data-clipboard-text="copyData" @click="copy">{{copyData}}</p>  
    </el-tooltip>

</template>
<script>
import Clipboard from 'clipboard'
import { Message } from 'element-ui'
export default {
  name:"CopyBoard",
  props:{
    copyData:{
      type:String,
      default:''
    },
  },
  methods:{
    copy() {  
      var clipboard = new Clipboard('.tag-read')  
      clipboard.on('success', e => {         
          Message.success({message:this.$t('message.copySuccess')})

              clipboard.destroy()  
            })  
            clipboard.on('error', e => {  
            Message.error({message:this.$t('message.copyFail')})
              clipboard.destroy()  
            })  
        },
  }
}
</script>